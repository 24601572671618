// imports
@import "../colors/colors";
@import "../mixins/padding";

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
.background {
  min-height: 100vh;
  background-size: cover;
  background-image: $primary-linear-background,
    url("../../../assets/images/background.jpg");
}
.background-mobile {
  min-height: 100vh;
  background-image: $secondary-linear-background;
}
.logo {
  left: -1px;
  height: 68px;
  width: 142.63px;
  margin-top: 106.97px;
  margin-bottom: -175px;
}
.logo-mobile {
  left: -1px;
  height: 58px;
  width: 121.66px;
  margin-top: 106.97px;
  margin-bottom: -175px;
}
.paper-normal {
  margin-top: 201px;
  border-radius: 4px;
}
.center {
  display: flex;
  justify-content: center;
}
.header {
  color: $title-charcoal;
  text-transform: none;
  letter-spacing: 0 !important;
  user-select: none;
  line-height: 20px !important;
}
.input_lable {
  letter-spacing: 0 !important;
  color: $secondary-Taupe-Gray !important;
  line-height: 21px !important;
  user-select: none;
}
.input_field {
  display: flex;
  justify-content: center;
  letter-spacing: 0 !important;
  color: $text-Black-Olive !important;
  &:-webkit-autofill:focus{
    font-family: "Open Sans", sans-serif !important;
  }
}
.input_props_field {
  line-height: 17.14px !important;
  color: $text-Black-Olive !important;
}
.center_terms_and_condition{
  display: flex;
  flex-direction: column!important;
  justify-content: center;
  margin-top: 8px !important;
  margin-left: 13px !important;
  align-items: center;
}
#agreement-box-error{
  color: $severity-Electric-Red;
}
.terms_and_condition{
  user-select: none;
  font-size: 12px;
}
.terms_and_condition_hyperlink{
  font-size: 12px;
  color:$secondary-Tufts-Blue;
  text-decoration: underline;
}
.login_button {
  color: $secondary-white;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-family: "Open Sans", sans-serif !important;
  text-transform: none !important;
  background-color: $primary-Jungle-Green !important;
  letter-spacing: 0.44px !important;
  margin-top: 39px !important;
}
.login_button:hover {
  background-color: $secondary-Celadon-green !important;
}
.login_button:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}
.link_nologin {
  letter-spacing: 0 !important;
  cursor: pointer;
  margin-top: 10px !important;
  user-select: none;
}
.link_signup {
  margin-bottom: 14px !important;
  font-weight: 600 !important;
  cursor: pointer;
  margin-top: 10px !important;
  text-decoration: underline !important;
  color:$secondary-Tufts-Blue;
  user-select: none;
}
.link_forgotpass{
  margin-top: 20px !important;
  font-size: 12px;
  color:$secondary-Tufts-Blue;
  text-decoration: underline;
  font-weight: 600 !important;
  cursor: pointer;
  user-select: none;
}
.footer {
  width: 139px;
  min-height: 40px;
  font-weight: 500;
  font-size: 14px;
  color: $secondary-white;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
}
input::-ms-clear, input::-ms-reveal {
    display: none;
}
.mt-20{
  margin-top: 20px!important;
}
