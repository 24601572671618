@import '../colors/colors';
@import '../mixins/padding';

// header and edit screen
.calendar_page_background {
    background: $mist-bleach !important;
}
.calendar_page_header {
    padding: 0px 30px 10px 30px;
}
.calendar_grid {
    width: 100%;
    display: grid;
    grid-gap: 5px;
    justify-content: end;
    grid-auto-flow: column;
    grid-template-columns: 170px 120px;
}
.calendar_title {
    height: 30px !important;
    font-size: 22px !important;
    line-height: 30px !important;
    letter-spacing: 0px !important;
    color: $Dark-Charcoal2 !important;
}
.calendar_add_inspection {
    height: 34px !important;
    width: 153px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    border-radius: 4px !important;
    letter-spacing: 0.5px !important;
    color: $secondary-white !important;
    background-color: $primary-Jungle-Green !important;
    // text-transform: none !important;
}
.calendar_add_inspection:hover {
    background-color: $secondary-Celadon-green !important;
}
.calendar_add_event {
    height: 34px !important;
    width: 120px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    border-radius: 4px !important;
    letter-spacing: 0.5px !important;
    color: $secondary-white !important;
    background-color: $primary-Jungle-Green !important;
    // text-transform: none !important;
}
.calendar_add_event:hover {
    background-color: $secondary-Celadon-green !important;
}
.calendar_header_icon {
    width: 17px !important;
    height: 17px !important;
    // text-transform: none !important;
}
.calendar_edit_screen_modal {
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $button-border !important;
}
.calendar_edit_screen_modal::-webkit-scrollbar {
    width: 0px;
    height: 2em;
}
.calendar_edit_modal {
    top: 35%;
    left: 50%;
    outline: 0;
    padding: 4px;
    max-width: 970px;
    position: relative;
    margin-bottom: 20%;
    border-radius: 5px;
    transform: translate(-50%, -25%);
    background-color: $secondary-white;
    // border: 2px solid $secondary-platinum;
}
.calendar-modal-close-icon {
    top: -25px;
    right: -25px;
    float: right;
    cursor: pointer;
    position: relative;
}
.calendar_edit_modal_buttons {
    margin-top: 68px !important;
    display: flex !important;
    justify-content: flex-end;
}
.calendar_edit_modal_buttons > button {
    height: 34px !important;
    width: 101px !important;
    margin-right: 15px;
}
.calendar-modal-title {
    height: 30px !important;
    width: 253px !important;
    font-size: 22px !important;
    line-height: 30px !important;
    letter-spacing: 0px !important;
    color: $Dark-Charcoal2 !important;
}
.calendar_edit_cancel_button {
    width: 79px !important;
    height: 34px !important;
    font-size: 13px !important;
    line-height: 24px !important;
    letter-spacing: 0.5px !important;
    text-decoration: underline !important;
}
.calendar_edit_save_button {
    width: 119px !important;
    height: 34px !important;
    font-size: 13px !important;
    color: $secondary-white !important;
    letter-spacing: 0.5px !important;
    border-radius: 4px !important;
    background-color: $primary-Jungle-Green !important;
}
.calendar_edit_save_button:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
}
.calendar_start_icon {
    height: 16px !important;
    width: 16px !important;
    vertical-align: middle !important;
    color: $primary-Spanish-Gray !important;
}
.calendar_allright_icon {
    width: 20px !important;
    height: 20px !important;
    display: flex !important;
    margin-top: 5px !important;
    color: $text-Black-Olive !important;
}
.calender_icon_center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.calendar_allday {
    font-size: 13px !important;
    letter-spacing: 0px !important;
    line-height: 21px !important;
    margin-right: 0px !important;
}
.calendar_forms_control {
    width: 100% !important;
    margin-bottom: 25px !important;
    padding: 0px 15px !important;
}

//custom toolbar
.calendar-toolbar-grid {
    padding: 10px 0px 21px 0px !important;
    vertical-align: middle !important;
}
.calendar-toolbar-date {
    height: 28.8px !important;
    color: $title-charcoal !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    line-height: 28.8px !important;
}
.calendar-toolbar-today-button {
    box-sizing: border-box !important;
    height: 34px !important;
    width: 70px !important;
    border: 1px solid $border-grey !important;
    border-radius: 4px !important;
    background-color: $secondary-white !important;
    margin-right: 13px !important;
}
.calendar-toolbar-back-button {
    box-sizing: border-box !important;
    height: 34px !important;
    width: 34px !important;
    margin-right: 13px !important;
    border: 1px solid $border-grey !important;
    border-radius: 4px !important;
    background-color: $secondary-white !important;
}
.calendar-toolbar-toggle-off {
    color: $secondary-Gray !important;
    font-size: 36px !important;
}
.calendar-toolbar-filter-title {
    height: 20px !important;
    cursor: pointer !important;
    font-size: 1rem !important;
    line-height: 20px !important;
    padding-left: 9px !important;
    margin-right: -15px !important;
    letter-spacing: 0px !important;
    color: $title-charcoal !important;
}

.calendar-toolbar-filter-title p{
    font-size: 16px !important;
}

.calendar-toolbar-button-group-month {
    box-sizing: border-box !important;
    height: 33px !important;
    width: 68.76px !important;
    border: 1px solid $border-grey !important;
    border-radius: 5px 0px 0px 5px !important;
    font-size: 12px !important;
    // font-weight: 600 !important;
    letter-spacing: 0px !important;
    line-height: 20px !important;
}
.calendar-toolbar-button-group-week {
    box-sizing: border-box !important;
    height: 33px !important;
    width: 83.82px !important;
    border: 1px solid $border-grey !important;
    font-size: 12px !important;
    // font-weight: 600 !important;
    letter-spacing: 0px !important;
    line-height: 20px !important;
}
.calendar-toolbar-button-group-day {
    width: 78px !important;
    height: 33px !important;
    font-size: 12px !important;
    // font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0px !important;
    box-sizing: border-box !important;
    border: 1px solid $border-grey !important;
    border-radius: 0px 5px 5px 0px !important;
}
.calendar-toolbar-group-button {
    display: flex !important;
    justify-content: end !important;
    align-items: flex-end !important;
}
.calendar-toolbar-grid-right {
    display: flex !important;
    justify-content: end !important;
    align-items: flex-end !important;
}

// custom date header
.calendar-ew-label {
    float: left;
    padding: 5px 0 0px 9px;
    color: $sova-blue;
    letter-spacing: 0;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}

//current date
.calendar-current-day {
    height: 23px;
    width: 23px;
    cursor: pointer;
    font-size: 13px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 18px;
    letter-spacing: 0;
    border-radius: 50%;
    padding: 2px 7px 2px 4px;
    color: $secondary-Smoke-White !important;
    background-color: $primary-Jungle-Green;
}
.calendar-all-day {
    display: flex;
    align-items: flex-end;
    padding: 0px !important;
    justify-content: flex-end;
    margin-top: 8px !important;
    margin-left: 13px !important;
    flex-direction: column !important;
}
//custom event
.calendar-month-event {
    font-size: 8px;
    font-weight: 600;
    line-height: 21px;
    border-radius: 3px;
    min-width: 100px;
    margin: 0px 2px 0px 2px;
}
.calendar-month-day-header {
    float: left;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    color: $light-blue;
    padding: 8px 0px 8px 6px;
    text-transform: uppercase;
    font-weight: bold !important;
}
.calendar-month-event-draft {
    font-size: 12px !important;
    font-weight: 600 !important;
    font-style: italic !important;
}
.calendar-month-event-time {
    font-size: 12px !important;
    line-height: 15px !important;
    letter-spacing: 0px !important;
    font-weight: bold !important;
    text-transform: lowercase !important;
}
.calendar-month-event-title {
    font-size: 12px !important;
    line-height: 15px !important;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-left: 3px !important;
}
.calendar-week-day-header {
    float: left;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    word-wrap: break-word;
    white-space: pre-line;
    color: $light-blue;
    border: #fff !important;
    padding: 7px 0px 23px 0px;
    text-transform: uppercase;
    font-weight: bold !important;
    background-color: #fff !important;
}
.calendar-month-view-label {
    font-size: 13px;
    float: left;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    height: 23px;
    margin-left: 5px;
    margin-top: 5px;
}
