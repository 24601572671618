@import '././assets/styles/mixins/_fonts.scss';

*,
html, 
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 13px;
}

// *:not(.agreement-view),
// html:not(.agreement-view), 
// body:not(.agreement-view) {
//   font-size: unset;
// }


*:not(.editor-class *),
html:not(.editor-class *), 
body:not(.editor-class *) {
  font-family: $OpenSans_Regular ;
}
.app_container{
  display: flex;
  justify-content: center;
  width: 100%;
}
.main__container{
  width: 100%;
  max-width: 1800px;
}
.header-fixed{
  max-width: 1800px;
  margin: auto;
}
@media only screen and (min-width: 1800px)  {
  #launcher,#webWidget {
    right: 1.5% !important;
  }
}
@media only screen and (min-width: 1900px)  {
  #launcher,#webWidget {
    right: 4% !important;
  }
}
@media only screen and (min-width: 2000px)  {
  #launcher,#webWidget {
    right: 7% !important;
  }
}
@media only screen and (min-width: 2100px)  {
  #launcher,#webWidget {
    right: 8% !important;
  }
}
@media only screen and (min-width: 2200px)  {
#launcher,#webWidget {
  right: 10% !important;
}
}
@media only screen and (min-width: 2300px)  {
  #launcher,#webWidget {
    right: 11% !important;
  }
}
@media only screen and (min-width: 2400px)  {
#launcher,#webWidget {
  right: 14% !important;
}
}
@media only screen and (min-width: 1800px)  {
  ._pendo-badge {
    right: 2% !important;
  }
}
@media only screen and (min-width: 1900px)  {
  ._pendo-badge {
    right: 5% !important;
  }
}
@media only screen and (min-width: 2000px)  {
  ._pendo-badge {
    right: 8% !important;
  }
}
@media only screen and (min-width: 2100px)  {
  ._pendo-badge {
    right: 9% !important;
  }
}
@media only screen and (min-width: 2200px)  {
._pendo-badge {
  right: 11% !important;
}
}
@media only screen and (min-width: 2300px)  {
  ._pendo-badge {
    right: 12% !important;
  }
}
@media only screen and (min-width: 2400px)  {
._pendo-badge {
  right: 15% !important;
}
}