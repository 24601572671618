@import "../colors/colors";
@import "../mixins/padding";

.guardian-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  .guardian-title {
    font-size: 20px;
    color: $title-charcoal;
  }
  .guardian-subtitle {
    color: $secondary-Taupe-Gray;
    font-size: 14px;
    margin: 5px 0px 8px 0px;
  }
  button {
    color: $secondary-white;
  }
}
.payments-subtitle {
  color: $title-charcoal;
  font-size: 14px;
  margin: 5px 0px 15px 0px;
}
.import__svg {
  svg {
    width: 80% !important;
    height: 40px;
  }
}
.guardian__svg {
  svg {
    height: 40px;
  }
}

.paltech_logo___svg {
  max-width: 170px !important;
  img {
    width: inherit;
    height: 25px;
  }
}
.guardian__desc {
  font-size: 14px;
  line-height: 20px;
  color: $title-charcoal;
  margin-top: 24px;
  margin-bottom: 15px;
}
.mt-0 {
  margin-top: 0px !important;
}
.guardian__action {
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: $Tufts-Blue;
  font-size: 14px;
  font-weight: 600;
  .arrow {
    display: flex;
  }
}
.settings-card.guardian {
  cursor: default;
}
.navlink.navlink.guardian {
  color: $Tufts-Blue !important;
}
.guardian__toggle {
  margin-top: 10px;
}
.guardian__svg__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.payment_status {
  font-size: 10px !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
  color: $Tufts-Blue !important;
  font-weight: bold !important;
}

.payment_status_icon {
  color: $Tufts-Blue !important;
  font-size: 16px !important;
}
.payment__toggle {
  display: flex;
  align-items: flex-start;
}
.payment__toggle__status {
  font-size: 14px !important;
  line-height: 20px !important;
  color: $title-charcoal !important;
  font-weight: 600 !important;
}
.p-8 {
  padding: 0px 8px;
}
.mh-170 {
  min-height: 170px !important;
}

.payment_desc {
  font-size: 14px;
  line-height: 20px;
  color: $title-charcoal !important;
  margin-top: 24px;
  margin-bottom: 15px;
}
