@import "../colors/colors";
@import "../mixins/index";

.color-picker-container div {
  min-height: 10px !important;
  min-width: 0px !important;
  box-shadow: none !important;
}

.popper-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.add-summary-color-picker > div:first-child {
  height: 250px !important;
}
.edit-summary-color-picker > div:first-child {
  height: 250px !important;
}
.edit-summary-color-picker {
  padding: 10px !important;
}
.add-summary-color-picker {
  padding: 10px !important;
}

.color-picker-popper {
  border-radius: 12px !important;
  background: var(--neutral-white, #fff) !important;
  box-shadow: 0px 8px 18px 0px rgba(35, 23, 5, 0.2) !important;
}
