// imports
@import "../colors/colors";
@import "../mixins/padding";
.back_arrow_icon {
    height: 24px !important;
    width: 24px !important;
    margin-bottom: -24px !important;
    margin-left: 16px !important;
    cursor: pointer;
  }
.back_signup_button {
  height: 21px !important;
  width: 20px !important;
  cursor: pointer;
  margin-bottom: -20px !important;
  margin-left: 16px !important;
  color: $primary-Sonic-Silver;
}
.full__width{
  div{
    width: 100%;
    padding: 0px 8px;
    label{
      padding: 0px 16px;
    }
  }
}
.form-container{
  padding-left: 30px!important;
  padding-right: 30px!important;
}