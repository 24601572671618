@import '../../../assets/styles/colors/colors';
@import "../../../assets/styles/mixins/padding";
@import "../../../assets/styles/mixins/_styles.scss";
@import '../../../assets/styles/mixins/_fonts.scss';


.datepicker{
    padding-left: 25px !important;

    &__buttons{
        cursor: pointer !important;

    }
    &__date{
        @include text($text-Black-Olive, 15px, normal, 21px, 0);
  @include widthHeight(183px, 24px);
  padding: 0px 11.5px !important;

    }
}