@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_styles.scss";
@import "../mixins/_fonts.scss";

.dashboard_title {
  color: $title-charcoal;
  font-size: 22px;
  word-wrap: break-word;
}

.dashboard_description {
  color: $title-charcoal;
  font-size: 16px;
  line-height: 30px;
}

.page-background {
  background-color: $white-smoke;
  min-height: 100vh;
  flex-grow: 1 !important;
}

.icon {
  color: $secondary-Smoke-White !important;
  @include widthHeight(22.5px, 25px);
  @include absCenter;
}

// monthly summary section

.dashboard__summary {
  color: $title-charcoal !important;
  font-size: 22px !important;
  padding: 48px 0 21px 0;

  &__box {
    border-radius: 4px !important;
    padding: 22px 22px 22px 22px !important;
    color: $secondary-white !important;

    &__icon {
      @include widthHeight(44px, 44px);
      border-radius: 4px !important;
      background-color: rgba(255, 255, 255, 0.25) !important;
    }

    &__total {
      font-size: 3.5rem !important;
      font-weight: 600 !important;
      @include RelativePosition(5px, 0, 0, 0);
    }

    &__title {
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }
}

//  daily route section
.route {
  @include widthHeight(100%, fit-content);
  @include borderLayoutHeader;
  margin-top: 82px !important;
  background-color: $secondary-white !important;
  padding: 26px 20px 42px 22px !important;

  &__header {
    @include flexCenter;

    &__title {
      @include text($title-charcoal, 22px, normal, 30px, 0);
      display: flex;

      div {
        @include text($title-charcoal, 22px, normal, 30px, 0);
      }
    }

    &__link {
      // width: 107px !important;
      @include flexCenter;
      white-space: nowrap;

      span {
        @include text($secondary-Tufts-Blue, 14px, 600, 21px, 0);
        cursor: pointer;
      }
    }
  }
}

.tabs {
  @include widthHeight(100%, 54px);
  margin-bottom: 30px !important;

  &__list {
    height: 54px;
    // @include widthHeight(25%, 54px);
    @include text($secondary-Arsenic, 14px, 600, 20px, 0.1);
    border-bottom: 1px solid $secondary-Arsenic !important;
  }
}

.jss6 {
  opacity: 0;
  transition: all ease 1000ms;
  /* So that it does not disappear quickly */
}

.jss1.Testimonial_carousel__3rny3:hover .jss6 {
  opacity: 1;
}

.address {
  @include flexCenter;
  padding-left: 15px;

  &__details {
    @include flexCenter;
    margin-left: -12px !important;
    // margin-left: 13.5px !important;
    width: 307.88px !important;

    &__list {
      div {
        @include text($title-charcoal, 18px, 600, 20px, 0);
        margin-bottom: 8px;
      }

      p {
        @include text($title-charcoal, 14px, normal, 20px, 0);
        position: relative;
        left: 14px;
        margin-bottom: 15px !important;
      }
    }
  }

  @media (max-width: 550px) {
    .address {
      &__details {
        // margin-left: -5rem !important;
        // display:flex !important
        width: 100% !important;
      }
    }
  }
}

//  upcomming insp

.upcomming {
  @include widthHeight(100%, auto);
  @include borderLayoutHeader;
  margin-top: 63px !important;
  background-color: $secondary-white !important;
  padding: 26px 20px 42px 22px !important;
}

@media screen and (min-width: 1440px) {
  .dashboard__summary {
    &__box {
      @include widthHeight(269px, 180px);
    }
  }
}

.modal-banner {
  position: fixed !important; /* Stay in place */
  z-index: 999 !important; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4) !important; /* Black w/ opacity */
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.close {
  cursor: pointer;
  // float: right !important;
  font-size: 24px !important;
  position: relative;
  display: flex;
  background: #f1f7fb;
  justify-content: flex-end;
  padding-right: 12px;
}
.banner {
  outline: none !important;
  background-color: $secondary-white !important;
  margin: 5% auto; /* 15% from the top and centered */
  @include widthHeight(680px, auto);

  // margin-bottom: 84px !important;
  &__container {
    width: 100%;

    &__items1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 230px !important;
      overflow: hidden;
      background-color: #f1f7fb !important;
    }

    &__items2 {
      padding: 10px 220px 10px 220px !important;
      text-align: center;
      width: 100%;
    }
  }

  &__image {
    // padding-top: 5px !important;
    // flex-shrink: 0;
    // margin: 0 auto !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: scale-down !important;
  }

  &__image2 {
    // padding-top: 20px !important;
    width: 100% !important;
    flex-shrink: 0;
    height: 100% !important;
    margin: 0 auto !important;
    object-fit: fill !important;
    // height: 100%;
    // object-fit: cover;
  }

  &__title {
    font-size: 34px !important;
    font-weight: 700 !important;
    color: #1d1f22 !important;
    padding-bottom: 12px !important;
    line-height: 46px !important;
  }

  &__description {
    margin: 0 auto !important;
    font-size: 17px !important;
    font-weight: 400 !important;
    color: #666666 !important;
    letter-spacing: -0.41px !important;
    line-height: 150% !important;
    // width: 70% !important;
    padding-bottom: 10px !important;
  }

  &__dots {
    display: flex;
    // justify-content: space-between;
    gap: 10px;
    width: 9.5% !important;
    margin: 0 auto !important;
    padding-bottom: 54px !important;

    &__one {
      @include widthHeight(16px, 12px);
      border-radius: 6px !important;
      background-color: #239d80 !important;
    }

    &__ones {
      @include widthHeight(16px, 12px);
      border-radius: 6px !important;
      background-color: #239d80 !important;
      opacity: 0.2 !important;
    }
  }

  &__switch {
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 10px;

    &__skip {
      padding: 13px 79px !important;
      font-weight: 400 !important;
      font-size: 15px !important;
      color: #666666 !important;
    }

    &__next {
      padding: 13px 79px !important;
      font-weight: 400 !important;
      font-size: 15px !important;
      max-width: 195px !important;
      max-height: 46px !important;
      color: #ffffff !important;
      border-radius: 8px !important;
      background-color: #239d80 !important;
    }
  }
}

.modal-banner::-webkit-scrollbar {
  display: none !important;
}

.caution-check-circle {
  margin-top: 72px !important;
  font-size: 51px !important;
  color: $severity-Electric-Red !important;
}
.no-inspection {
  height: 45px;
  font-size: 22px;
  margin-top: 35px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  color: $title-charcoal;
}
.no-insp-desc {
  height: 71px;
  max-width: 439px;
  
  display: inline;

  color: $title-charcoal;
  font-size: 14px;
  letter-spacing: 0;
  align-self: center;
  line-height: 21px;
  text-align: center;
}

@media only screen and (max-width: 520px) {
  .close {
    background: #ffffff;
  }
  .banner {
    top: 50% !important;
    margin: 50% auto;
    @include widthHeight(100%, auto);
    &__container {
      &__items1 {
        display: none !important;
      }
      &__items2 {
        padding: 60px 40px !important;
        width: 100%;
      }
    }

    &__title {
      font-size: 30px !important;
      padding-top: 10px !important;
      padding-bottom: 12px !important;
    }

    &__description {
      width: 72% !important;
    }

    &__dots {
      width: 22% !important;
    }

    &__switch {
      width: 90%;

      &__skip {
        padding: 5px 30px !important;
      }

      &__next {
        padding: 5px 30px !important;
      }
    }
  }
}
