@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @mixin absCenter {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @mixin verticalCenter {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
  }

  @mixin commonLayoutWidth($width, $height, $padding, $margin) {
    width: $width !important;
    height : $height !important;
    padding: $padding !important;
    margin: $margin !important;
  }

  @mixin RelativePosition($top, $right, $bottom, $left) {
    position: relative;
    top: $top !important;
    right: $right !important;
    bottom: $bottom !important;
    left: $left !important;
  }

  @mixin widthHeight($width, $height) {
    width: $width !important;
    height : $height !important;
  }
  
  @mixin commonLayoutMobileWidth {
    width: 100%;
    margin: 0;
  }

  @mixin BgTexture {
    background-color: $secondary-white;
  }

  @mixin text( $color, $size, $weight, $height, $spacing) {
    font-family: $OpenSans_Regular !important;
    font-size: $size !important;
    font-weight: $weight !important;
    line-height: $height !important;
    color: $color !important;
    letter-spacing: $spacing !important;
  }

  @mixin borderLayoutHeader {
    box-shadow: none !important;
    border-radius: 4px !important;
    border: 1px solid $secondary-platinum !important;
    box-sizing: border-box !important;
  }
  