// imports
@import "../colors/colors";
@import "../mixins/padding";

@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-SemiBold.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
*:not(.editor-class *) {
    font-family: "Open Sans", sans-serif ;
}
.settings-card {
    cursor: pointer;
    min-height: 190px;
    .details-cards {
        flex-direction: column !important;
         padding: 20px 28px 35px 18px;
        svg {
            font-size: 24px;
        }
        label {
            width: 100%;
        }
    }
    .icon-container {
        width: 51px;
        height: 51px;
        background-color: $background-Cultured;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 13px;
        svg {
            // height: 37px;
            // width: 37px;
            color: $Tufts-Blue;
        }
    }
    .name-wrapper {
        display: flex;
        align-items: normal;
        .setting-name {
            color: $text-Black-Olive;
            font-size: 18px;
            line-height: 21px;
        }
        svg{
            color: $Tufts-Blue;
            margin-left: 12px;
        }
    }
    .setting-name-desc{
        color: $title-charcoal;
        font-size: 14px;
        line-height: 20px;
    }
}
.report_settings_modal .MuiDialog-paper{
    width: 480px;
    max-width: 480px;
}
.report_setting_content{
    padding: 20px 20px 0px 20px!important;
}
.report_settings_action{
    justify-content: center!important;
}
@media only screen and (max-width: 900px) {
    .details-title p{
        padding-left: 16px;
    }
    .settings-card .details-cards {
        min-height: 240px;
    }
}
@media only screen and (max-width: 920px) {
    .settings-card .details-cards {
        min-height: 240px;
    }
}
