@import '../assets/styles/colors/colors';

.toastbar {
    background-color: $Dark-Charcoal;
    color: $secondary-white;
    border-radius: 4px;
    min-width: 434px;
    display: flex;
    justify-content: space-between;
    padding: 14px 10px 14px 10px;
}

.toast_message {
    float: left;
}

.toast_close_icon_display {
    display: flex;
}

.toast_close_icon {
    cursor: pointer;
    margin-left: auto;
    float: right;
}

// universal change for error message
.MuiFormHelperText-root{
    font-size: .9rem !important;
    color: $severity-Electric-Red !important;

}

// universal color change for outlined textbox when hover
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{ &:hover{
    border-color:$text-Black-Olive !important
}}

  @media only screen and (min-width: 1800px)  {
    .toastbar {
      margin-left: 1.5% !important;
    }
  }
  
  @media only screen and (min-width: 1900px)  {
    .toastbar {
      margin-left: 4% !important;
    }
  }
  
  @media only screen and (min-width: 2000px)  {
    .toastbar {
      margin-left: 7% !important;
    }
  }
  
  @media only screen and (min-width: 2100px)  {
    .toastbar {
      margin-left: 8% !important;
    }
  }
  
  @media only screen and (min-width: 2200px)  {
    .toastbar {
      margin-left: 10% !important;
    }
  }
  
  @media only screen and (min-width: 2300px)  {
    .toastbar {
      margin-left: 11% !important;
    }
  }
  
  @media only screen and (min-width: 2400px)  {
    .toastbar {
      margin-left: 14% !important;
    }
  }
  
