@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_styles.scss";
@import "../mixins/_fonts.scss";

.pt_is_flex {
  display: flex;
}
.pt_is_flex_align_center {
  display: flex;
  align-items: center;
}
.pt_all_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pt_space_between {
  justify-content: space-between;
}
.show_in_library_header {
  min-width: 160px !important;
  .MuiTableSortLabel-root {
    flex-direction: row !important;
    gap: 7px;
  }
}
.pt_w_100 {
  width: 100%;
}
.pt_pl_10{
  padding-left: 10px!important;
  .MuiSelect-select{
    height: 23px!important;
  }
}
.pointer:hover {
  cursor: pointer;
}
.template_name_container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.pdf_icon {
  font-size: 15px !important;
}
.pdf_icon_details {
  font-size: 22px !important;
}
.template_info_icon {
  font-size: 12px !important;
  margin-bottom: 6px !important;
  cursor: pointer;
}
.template_info_icon_cat_type {
  font-size: 13px !important;
  margin-bottom: 2px !important;
  cursor: pointer;
}
.pt-delete-template-modal,
.pt-info-template-modal {
  .MuiDialog-paper {
    padding: 50px 34px 30px 34px;
    align-items: center;
    width: 655px;
    max-width: 655px;
  }
  .template_delete_modal_text {
    @include text($title-charcoal, 22px, normal, 30px, 0);
    margin-top: 15px;
    word-wrap: break-word;
  }
  h2 {
    color: $text-Black-Olive;
    font-size: 22px;
    line-height: 30px;
    padding: 9px;
  }
  .MuiDialogActions-root {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: $text-Black-Olive;
    margin-bottom: 12px;
  }
  .close-icon {
    color: $text-Black-Olive;
  }
}
.pt-info-template-modal {
  .MuiDialog-paper {
    padding: 50px 34px 30px 34px;
    align-items: center;
    width: 559px;
    max-width: 559px;
  }
  .MuiDialogActions-root {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
.dialog-action button {
  margin-top: 15px;
  color: $secondary-white;
}
.template-delete-modal-cancel-btn {
  width: 101px !important;
  height: 34px !important;
  border: 1px solid $Spanish-Gray !important;
  color: $primary-Sonic-Silver !important;
  border-radius: 2px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-right: 8px !important;
}
.default-btn-template-delete-modal {
  background: #239d80 !important;
  width: 119px !important;
  height: 34px !important;
  font-size: 13px !important;
  border-radius: 2px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
}
// ==============================================================
// pt template table
.pt_template-table-head {
  position: relative;
  width: 228px;
  color: #9a9a9a;
  font-size: 12px;
  line-height: 19.14px;
}
.pt_template-table-head:nth-child(2) {
  position: relative;
  min-width: 125px;
}
.pt_template-table-head:nth-child(3) {
  position: relative;
  min-width: 120.62px;
}
.pt_template-table-head:nth-child(4) {
  position: relative;
  min-width: 106.15px;
}

.pt_template-table-head:nth-child(5) {
  position: relative;
  min-width: 106.15px;
  margin-left: 25.08px;
}
.pt_template-table-head:nth-child(6) {
  position: relative;
  max-width: 239.32px !important;
  word-wrap: break-word !important;
  margin-left: 22.19px;
}
.pt_template-table-head:nth-child(7) {
  position: relative;
  min-width: 106.15px;
}

.pt_template-table-head:nth-child(8) {
  position: relative;
  min-width: 126px;
  margin-left: 23px;
}
.pt_template-table-head:nth-child(9) {
  position: relative;
  min-width: 56px;
  margin-left: 20px;
  margin-right: 23px;
}
// =============================================================
.pt-add-template-modal {
  .MuiDialog-paper {
    padding: 33px 35px 33px 33px;
    align-items: center;
    width: 970px;
    max-width: 970px;
  }
  .pt_new_temp_modal_heading {
    span {
      @include text($title-charcoal, 22px, 400, 30px, normal);
    }
  }
  .MuiDialogContent-root {
    padding: 0px !important;
  }
  .pt_new_temp_modal_inner_container {
    padding: 28px 54px 0px 32px;
  }
  .pt_input-form-label {
    @include text($text-Black-Olive, 12px, 400, 21px, normal);
    text-transform: uppercase;
  }
  .template_delete_modal_text {
    @include text($title-charcoal, 22px, normal, 30px, 0);
    margin-top: 15px;
    word-wrap: break-word;
  }
  h2 {
    color: $text-Black-Olive;
    font-size: 22px;
    line-height: 30px;
    padding: 9px;
  }
  .pt_edit_te_action {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .pt_non_edit_te_action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: $text-Black-Olive;
    margin-bottom: 12px;
  }
  .close-icon {
    color: $text-Black-Olive;
  }
}
.mb-34 {
  margin-bottom: 34px !important;
}
.pt_disabled_btn {
  background: #d8d8d8 !important;
  width: 119px !important;
  height: 34px !important;
  font-size: 13px !important;
  border-radius: 2px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  span {
    color: #ffff;
  }
}
.pt_caret {
  color: $text-Black-Olive;
  font-size: 24px;
  position: absolute;
  right: 10px;
}
.pt_caret.caret_active {
  transform: rotate(180deg);
}
// Template editor
.pt__templates {
  margin-bottom: 27px;
}
.PtTemplateEditor_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.PtTemplateEditor_wrapper_sm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse!important;
  padding: 0px 10px;
}
.pt_layout {
  width: 65.46%;
}
.pt_layout2 {
  width: 31.35%;
  position: sticky;
  top: 500px;
}
.pt_layout__header {
  display: block;
}

.pt_layout__header1 {
  display: none;
}

.pt_layout__title {
  @include text($title-charcoal, 22px, normal, 30px, 0);
  word-break: break-all;
  span{
    @include text($title-charcoal, 22px, normal, 30px, 0);
    display: flex;
    align-items: end;
    gap:6px;
  }
}

.pt_layout__description {
  text-align: left;
  font-size: 14px;
  padding-top: 5px;
  color: $title-charcoal;
  word-wrap: break-word !important;
}

.pt_layout__tabs {
  width: 100%;
  display: flex;
  margin-top: 26px;
  justify-content: flex-start;
  border-bottom: 1px solid $secondary-Light-Grey;
}

.pt_layout__tabs__select {
  color: $primary-Jungle-Green;
  width: 100%;
}

.pt_layout__tabs__selected {
  @include text($secondary-Arsenic, 14px, 600, 20px, 0.1px);
  width: 25%;
}
.pt_template__accordion {
  border: 1px solid $secondary-platinum;
  border-radius: 4px;
  box-shadow: none !important;
  margin-bottom: 16px;
}

.pt_template__accordion_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 7.5px 18px 7.5px 9px;
  &:hover {
    cursor: pointer;
  }
}
.pt_pointer{
  cursor: pointer;
}
.pt_template__accordion_content.pt_border_bottom {
  border-bottom: 1px solid $secondary-platinum;
}
.pt_ta_name {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 1;
  width: 90%;
  svg {
    font-size: 18px;
  }
}
.pt_drag_disabled{
  color: $secondary-Gray!important;
}
.pt_ta_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  svg {
    font-size: 22px;
  }
  .pt_ta_dropdown.accord_active {
    cursor: pointer;
    transform: rotate(-180deg);
    transition: transform 0.4s ease;
  }
  .pt_ta_dropdown.accord_inactive {
    cursor: pointer;
    transition: transform 0.4s ease;
  }
}
.pt_ta_cat_title {
  @include text($title-charcoal, 18px, 400, 30px, 0.1px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.pt_cat_italic {
  font-style: italic!important;
  transform: skew(-5deg, 0);
}
.pt_cat_disabled{
  @include text($secondary-Gray, 18px, 400, 30px, 0.1px);
}
.pt_cat_type_text_reduced{
  @include text($GunSmoke, 9px, 700, 9px, normal);
  font-style: italic;
  text-transform: uppercase;
  background: $Lime-Gray;
  border-radius: 15px;
  padding: 4px 12px;
  display: inline-block;
  margin-left: 10px;
  margin-top: -2px;
}
.pt_cat_type_text_info{
  @include text($secondary-Tufts-Blue, 9px, 700, 9px, normal);
  font-style: italic;
  text-transform: uppercase;
  background: $Pattens-Blue;
  border-radius: 15px;
  padding: 4px 12px;
  display: inline-block;
  margin-left: 10px;
  margin-top: -2px;
}
.pt_ta_active_border {
  border-left: 4px solid $secondary-Tufts-Blue;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pt_ta_content_padding {
  padding: 36px 60px;
}
.pt_card_border {
  border: 1px solid $secondary-platinum;
  border-radius: 4px;
}
.pt_inputfield {
  min-height: 150px;
  display: flex;
  gap: 17px;
  margin-bottom: 14px;
  background: #fff;
}
.pt_inputfield_small {
  min-height: 84px;
  display: flex;
  gap: 17px;
  margin-bottom: 14px;
  background: #fff;
}
.pt_icon_color {
  color: $text-Black-Olive;
}
.pt_ip_drag_section {
  background-color: $sova-light-grey;
  display: flex;
  align-items: center;
  &:hover {
    cursor: grab;
  }
}
.pt_ip_drag_section.pt_elem_dragging {
  background-color: $Tufts-Blue;
  .pt_icon_color {
    color: #fff;
  }  
}
.pt_ip_content {
  padding: 20px 6px 20px 0px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  flex-grow: 1;
}
.pt_ip_data {
  flex-grow: 1;
}
.pt_ip_data_row1 {
  flex-grow: 1;
  margin-bottom: 14px;
  .MuiFormControl-root {
    max-width: 126px !important;
  }
  svg {
    font-size: 18px;
    color: #1c1b1f;
  }
}
.pt_element_span {
  height: 18px;
  width: 18px;
  border: 2px solid #1c1b1f;
  color: #1c1b1f;
  font-weight: bold;
}
.gap5 {
  gap: 5px;
}
.pt_element_name {
  @include text($text-Black-Olive, 18px, 400, 28px, normal);
  cursor: context-menu;
}
.pt_merge_field{
  // display: none;
  background: rgba(255, 222, 105, 0.18);
  border-left: 3px solid #FFDE69;
  padding: 10px 13px;
  margin-bottom: 15px!important;
  .MuiInputBase-root{
    width: 100%;
  }
  .MuiFormHelperText-root{
    background:  rgba(255, 222, 105, 0.18)!important;
    margin: 0px!important;
    padding-top:3px;
  }
  .pt_focussed .Mui-focused fieldset{
    border-color: #d32f2f!important;
  }
}
.pt_form_label {
  @include text($text-Black-Olive, 12px, 400, 21px, normal);
  text-transform: uppercase;
  margin-bottom: 7px;
}
.pt_form_label_cat_type {
  @include text($text-Black-Olive, 12px, 400, 21px, normal);
  text-transform: uppercase;
  margin-bottom: 7px;
}

.pt_add_elem_btn {
  margin: 0px 0px 14px 0px;
  button {
    width: 100% !important;
    height: 49px !important;
    border: 1px solid $secondary-platinum;
    @include text($secondary-Tufts-Blue, 14px, 600, 21px, normal);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span{
      @include text($secondary-Tufts-Blue, 14px, 600, 21px, normal);  
    }
    &:hover {
      border: 1px solid $secondary-platinum;
      @include text($secondary-Tufts-Blue, 14px, 600, 21px, normal);
      background: transparent;
    }
  }
}
.pt_add_elem_btn.pt_elem_disabled {
  cursor: not-allowed!important;
}
.pt_add_elem_btn {
  ul {
    padding-top: 8px !important;
  }
  li {
    padding-left: 20px !important;
  }
  .MuiListSubheader-root {
    padding-left: 11px !important;
    @include text($text-Black-Olive, 10px, 400, 21px, normal);
    text-transform: uppercase;
  }
  .pt_add_elem_btn_li_item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $Dark-jungle;
    svg {
      width: 12px;
      height: 12px;
    }
    span {
      @include text($Dark-jungle, 12px, 400, 16px, normal);
    }
  }
}
.pt_add_elem_btn.el_btn_active {
  button {
    border: 1px solid $secondary-Tufts-Blue;
  }
}
.pt_ta_exp_checkbox_expand_icon {
  font-size: 22px !important;
}
.pt_ta_exp_checkbox_expand_icon.accord_active {
  transform: rotate(-180deg);
  transition: transform 0.4s ease;
}
.pt_ta_exp_checkbox_expand_icon.accord_inactive {
  transition: transform 0.4s ease;
}
.pt_elem_count_span{
  display: flex;
  align-items: center;
  gap: 7px;
  span{ 
  font-style: italic;
  @include text($Mountain-Mist, 12px, 400, 21px, normal);
  }
 
}
.pt_more_menu {
  ul {
    padding: 10px;
    min-width: 140px;
  }
  span {
    display: flex;
    justify-content: flex-end;
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
  li {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
.pt_ip_action.pt_ip_enabled {
  svg:hover {
    cursor: pointer;
  }
}
.imported_icon svg {
  width: 18px;
  height: 18px;
}
.pt_divider_line {
  display: block;
  width: 100%;
  height: 12px;
  border-radius: 4px;
  background: $Platinum;
}
.pt_info_editor {
  .se-wrapper {
    min-height: 100px !important;
  }
  .se-wrapper-inner {
    height: 99px !important;
    p {
      line-break: anywhere;
    }
  }
}
.pt_editor_type_title {
  @include text($text-Black-Olive, 12px, 400, 21px, normal);
  text-transform: uppercase;
}
.pt_table_box {
  display: flex;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: $Platinum;
  @include text($secondary-black, 14px, 400, 28px, normal);
  padding: 12px 9px;
  align-items: center;
}
.pt_add_pdf_file {
  border: 1px dashed $Spanish-Gray;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 36px;
  min-width: 220px;
  padding: 8px 12px;
  margin-left: 25px;
  cursor: pointer;
  p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $secondary-Tufts-Blue;
    margin-bottom: 0px !important;
  }
  svg {
    font-size: 18px;
  }
  span {
    @include text($secondary-Tufts-Blue, 14px, 600, normal, normal);
  }
}
.pt_pdf_items {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 40px;
  margin-top: 5px;
  span{
    cursor: pointer;
  }
  svg {
    font-size: 18px !important;
  }
  span{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
    @include text($secondary-Tufts-Blue, 14px, 400, 20px, normal);
    svg{
      font-size: 14px!important;
      cursor: pointer;
    }
  }
  
}

.loading_text:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color:$secondary-Tufts-Blue;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 $secondary-Tufts-Blue, 0.5em 0 0$secondary-Tufts-Blue;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 $secondary-Tufts-Blue, 0.5em 0 0 $secondary-Tufts-Blue;
  }
}
// ==========================================================================
// publish actions.. right hand side
.pt__sticky{
  position:sticky;
  top:235px;
  background:#fff;
}
.pt__normal_actions_container{
  background:#fff;
  min-height:200px;
}
.pt_publish_action{
  border-radius: 4px;
  padding: 25px 28px;
  border: 1px solid $secondary-platinum;  
  min-height:310px;
}
.pt_temp_editor_cta {
  height: 34px!important;
  width: 100% !important;
  border-radius: 2px!important;
  font-size: 13px!important;
  line-height: 24px!important;
  font-weight: 600!important;
  margin-bottom: 8px!important;
  text-transform: none!important;
}

.pt_temp_editor_cta.pt_cta_filled.pt_action_disabled {
  cursor: not-allowed!important;
  background: #d8d8d8 !important;
  border: 1px solid #d8d8d8 !important;
  &:hover{
    background: #d8d8d8 !important;
    border: 1px solid #d8d8d8 !important;
    color: $secondary-white!important;
}
}
.pt_cta_normal {
  border: 1px solid $Mountain-Mist!important;
  color: $primary-Sonic-Silver !important;
  &:hover{
      background: $primary-Jungle-Green!important;
      border: 1px solid $primary-Jungle-Green !important;
      color: $secondary-white!important;
  }    
}
.pt_cta_filled {
  border: 1px solid $primary-Jungle-Green!important;
  color: $secondary-white !important;
  background: $primary-Jungle-Green!important; 
  &:hover{
      background: $primary-Jungle-Green!important;    
  }
}
.pt_cta_filled_blue {
  width: 100%;
  margin-top:15px!important;
  color:  $Tufts-Blue !important;
  p{
    text-decoration: underline;
    text-transform: none;
  }
  &:hover{
    background-color: transparent!important;
  }
}
.pt_cta_normal.pt_action_disabled{
  cursor: not-allowed!important;
  &:hover{
    border: 1px solid $Mountain-Mist!important;
    color: $primary-Sonic-Silver !important;
    background: white!important;
}

span {
  color: #ffff;
}
}
.pt_status_section{
  margin-bottom: 10px;
  .pt_status_title{
    @include text($secondary-Taupe-Gray, 12px, 400, 21px, normal);
  }
  .pt_status_content{
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    height: 18px;
    border-radius: 15px;
    text-transform: uppercase;
  }
  .pt_unpublished{
    background: #FFECD2;
    @include text(#F35F01, 9px, 700, normal, normal);
  }
  .pt_published{
    background: #C7F6E0;
    @include text(#1F9C7B, 9px, 700, normal, normal);
  }
  p{
    margin-top: 10px;
    @include text($title-graphite, 12px, 400, 20px, normal);
    margin-bottom: 5px;
  }
}
.pt_status_action_section{
  padding: 25px 20px;
}
.pt_status_notify_section{
  padding: 0px 20px;
  p{
    @include text($secondary-Taupe-Gray, 12px, 400, 20px, normal);
    text-align: center;
  }
}
// ==========================================================================
.pt_delete_template_btn {
  margin-left: 2% !important;
  padding: 5px 33px 5px 33px !important;
  border: 1px solid $secondary-Light-Grey !important;
  color: $title-charcoal !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.pt_delete_template_btn.pt__disabled_btn {
  border: 1px solid $secondary-Light-Grey !important;
  color: $title-charcoal !important;
  cursor: not-allowed!important;
  background: #e5e5e5 !important;
}
.pt_radio_labels_container{
  display: flex;
  flex-wrap: wrap;
  margin: 22px 12px!important;
  .pt_radio_label{
    display: flex;
    align-items: center;
    flex: 0 0 calc(33.3333% - 0px); 
    box-sizing: border-box;
    min-height: 40px!important;
    padding: 5px 10px;
    gap: 8px;
    span{
      @include text($text-Black-Olive, 16px, 400, 24px, normal);
    }
  }
  .pt_radio_label_input{
    display: flex;
    align-items: center;
    flex: 0 0 calc(33.3333% - 0px); 
    box-sizing: border-box;
    min-height: 40px!important;
    gap: 8px;    
    margin-bottom: 8px!important;
    span{
      @include text($text-Black-Olive, 16px, 400, 24px, normal);
    }
    input{
      border-radius: 3px;      
      max-width: 146px;
      margin-right: 3px;
      height: 32px !important;
      border: 1px solid #6d7278!important;
      border-radius: 3px;
      padding: 0px 10px;
    }
    svg{
      color:#6d7278!important;
    }
    .radio_del_icon {
      color: $title-graphite;
      opacity: 0.7;
      &:hover {
        color: $severity-Electric-Red!important;
        transition: color 150ms linear;
        cursor: pointer;
      }
    }
  }
}
.pt_ad_choice{
  margin-left: 10px;
}
.pt_radio_editButton {
  @include widthHeight(136px, 34px);
  @include borderLayoutHeader;
  color: $text-Black-Olive !important;
  // margin: 32px 0px !important;

  &__text {
    margin-left: 6.7px !important;
    @include text($title-charcoal, 13px, 600, 24px, 0.5);
  }
}
.pt_radio_error{
  color: $severity-Electric-Red;
  font-size: 12px;
}
@media only screen and (max-width: 1250px) {
  .pt_status_action_section{
    padding: 25px 5px;
  }
  .pt_cta_filled{
    padding: 6px 1px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .pt_publish_action{
    padding: 25px 18px;
  }
}
@media only screen and (max-width: 960px) {
  .pt_publish_action{
    padding: 25px 8px;
  }
}