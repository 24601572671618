@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_styles.scss";
@import "../mixins/_fonts.scss";

.headingGrid {
  display: flex;
  align-items: center;
  padding: 8px 20px;
}
.importHeading {
  font-size: 20px !important;
  color: $text-Black-Olive !important;
  font-weight: bold !important;
}

.importTitle {
  color: $secondary-Taupe-Gray !important;
  font-size: 14px !important;
  margin: 5px 0px 8px 0px !important;
}

.addNewImport {
  border: none !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  background-color: #239d80 !important;
  box-shadow: none !important;
  width: 119px !important;
  text-transform: none !important;
  height: 34px !important;
  position: relative !important;
  top: 10px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 0.04px !important;
  line-height: 24px !important;
  cursor: pointer !important;
}

.ptCompanyListTable {
  padding: 0px 0px !important;
}

.ptHeadingTitle {
  color: #9a9a9a !important;
  font-family: Open Sans !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 19.143px !important;
  text-transform: uppercase !important;
  border-bottom: 0px !important;
}

.ptHeadRow {
  display: flex !important;
}

.ptPagination {
  padding: 0px 25px 0px 5px !important;
}

.ptRowColor {
  cursor: pointer;
  &:nth-child(odd) {
    background-color: $secondary-cultered;
  }
}

.ptCell {
  border-bottom: 0px !important;
}

.importHeading {
  font-size: 20px !important;
  font-weight: bold !important;
}

.ptDialogButton {
  background-color: #239d80 !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: 0.04px !important;
  padding-inline: 20px !important;
}
.ptDialogButton:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}

.ptCancelButton {
  background-color: #ffffff !important;
  color: #9a9a9a !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: 0.04px !important;
  padding-inline: 20px !important;
  border: 1px solid #9a9a9a !important;
}

.uploadBox {
  border: 2px dashed #71a1e9 !important;
  background-color: #f3f8fe !important;
  margin: 20px 0px !important;
  padding: 20px 0px !important;
  cursor: pointer !important;
}

.uploadTitle {
  font-weight: bold !important;
  font-size: 18px !important;
  color: #4d89e4 !important;
}

.ptUploadDesc {
  font-weight: bold !important;
  font-size: 18px !important;
}

.ptFileCard {
  width: 60px !important;
  height: 60px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ptFileIcon {
  font-size: 34px !important;
  color: #239d80 !important;
}

.ptFileName {
  font-weight: bold !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
}

.ptFileSize {
  font-size: 10px !important;
  color: #9a9a9a !important;
}

.fileBox {
  background-color: #f3f3f3 !important;
  padding: 16px !important;
  margin-bottom: 16px;
  border-radius: 5px !important;
}

.ptDoneIcon {
  color: #4282e2 !important;
  font-size: 28px !important;
}

.ptSuccessTitle {
  font-size: 32px !important;
  font-weight: 400 !important;
}

.ptUploadTitle {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.optionHeading {
  color: #333 !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.importDialogHeading {
  color: #333 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  padding-top: 20px !important;
}
.optionButtons {
  border: none !important;
  color: $secondary-white !important;
  border-radius: 4px !important;
  background-color: $primary-Jungle-Green !important;
  box-shadow: none !important;
  width: 200px !important;
  text-transform: none !important;
  height: 34px !important;
  position: relative !important;
  top: 10px;
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 0.04px !important;
  line-height: 24px !important;
  margin-left: 28.8px !important;
  cursor: pointer !important;
  &:hover {
    background: #1e8970 !important;
  }
}

.ptTextButton {
  margin-bottom: 8px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-left: 4px !important;
}

.templatePaperDesign {
  border: 1px grey solid !important;
  padding: 20px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  width: 266px !important;
  height: 83px !important;
  cursor: pointer !important;
}

.templateContactUs {
  color: #5a92e6 !important;
  text-decoration: none !important;
}

.ptDisableBtn {
  border: 1px solid #d8d8d8 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  background-color: $secondary-Gray !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: 0.04px !important;
  padding-inline: 20px !important;
}

.dateTimeColor {
  color: #797979 !important;
  cursor: default !important;
}

.ptDownloadBtn {
  cursor: pointer !important;
  color: #4d89e4 !important;
}

.ptCompanyDetails {
  color: #333 !important;
  font-family: Open Sans !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.ptBeginImportButton {
  color: #4282e2 !important;
  text-align: right !important;
  font-family: Open Sans !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.dotImage {
  padding-top: 6px !important;
  font-size: 16px !important;
}

.importTitleDesc {
  color: #333 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  margin-top: 8px !important;
  margin-left: 32px !important;
}

.optionDialogTitle {
  color: #333 !important;
  text-align: center !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

.optionDialogDesc {
  color: #333 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: center !important;
}

.importDialogTitle {
  color: #333 !important;
  text-align: center !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

.optionDialogTypo {
  color: #333 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: center !important;
}