@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_fonts.scss";
@import "../mixins/_styles.scss";

.accordition {
  width: 100%;
  box-sizing: border-box;
  height: 53px;
  border: 1px solid #e7e7e9;
  // border-radius: 4px;
  padding: 0 18px 0 13px;
  cursor: pointer;
  background-color: #ffffff;
  @include flexCenter;
  &__draggable {
    width: 8%;
    font-size: 18px;
    opacity: 0.5;
  }
  &__title {
    width: 85%;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__title__mobile {
    width: 78%;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__open {
    width: 3%;
    font-size: 20px;
    color: #3b3b3b;
  }
  &__menus {
    position: relative;
    top: 3px;
    display: inline-block;
    // font-size: 20px;
    // color: #3B3B3B;
  }
}

.delete-wrappers {
  color: gray !important;
  position: absolute;
  margin-top: 12px;
  margin-left: -32px;
  cursor: pointer;
  // background-color: red !important;
}

.delete-wrappers:hover {
  color: #e30303 !important;
}

.accordion-content {
  width: 100%;
  border: 1px solid #e7e7e9;
  background-color: #ffffff;
  border-top: none !important;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.templates {
  @include flexCenter;

  &__icon {
    color: $text-Black-Olive !important;
    font-size: 25px !important;
    cursor: pointer !important;
  }

  &__add {
    width: 100%;
    @include borderLayoutHeader;
    @include flexCenter;
    cursor: pointer !important;
    // margin-top: 15px !important;
    justify-content: flex-start !important;
    color: $secondary-Tufts-Blue;
    padding-left: 14px !important;

    &__text {
      margin-left: 6px !important;
      @include text($secondary-Tufts-Blue, 14px, 600, 19px, 0);
    }
  }
}

.editorfix {
  min-height: 140px !important;
}

.editorfix .sun-editor .se-wrapper-inner {
  min-height: 140px !important;
  resize: vertical;
}

.Mui-expanded {
  background-color: "red !important";
}

.inspection {
  margin-top: 27px !important;

  &__accordion {
    box-shadow: none !important;
    // border-radius: 4px !important;
    margin: 0px !important;
    border: 1px solid #e7e7e9 !important;
    box-sizing: border-box !important;

    &__main {
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      min-height: 0px;
      height: 54px !important;
      // z-index: unset !important;
      // box-shadow: -5px 6px 8px 1px #e1e1e1;
      // border-bottom: 1px solid $secondary-platinum !important;
    }

    &__title {
      @include flexCenter;
      justify-content: flex-start !important;
    }

    &__text {
      padding-left: 14px !important;
      // font-size: 18px !important;
      font-weight: normal !important;
    }

    &__summary {
      // border-left: 4px solid $secondary-Tufts-Blue !important;
      // padding : unset !important;
    }
  }
}

.temp-lib-table-head {
  position: relative;
  color: $Spanish-Gray2;
  font-size: 12px;
  line-height: 19.14px;
}
.temp-lib-table-head:nth-child(1) {
  position: relative;
  // padding-left: 20px;
  padding-right: 50px;
}
.temp-lib-table-head:nth-child(2) {
  position: relative;
}

.modal_title {
  @include text($title-charcoal, 22px, normal, 30px, 0);
}

.modal_desc {
  @include text($primary-Sonic-Silver, 14px, normal, 20px, 0);
  margin-top: 6px !important;
}

.maincat {
  font-size: 24px !important;
  font-weight: normal !important;
  color: $text-Black-Olive !important;
}

.MuiAccordionSummary-expandIconWrapper {
  position: relative !important;
  right: 38px !important;
}

.category-search {
  @include widthHeight(92%, 53px);
  @include borderLayoutHeader;
  margin: 0 auto !important;
  padding-left: 43px;
  @include text($secondary-Gray, 18px, normal, 20px, 0);
  opacity: 0.5;
}

.search-icon {
  position: absolute !important;
  top: 33.5% !important;
  font-size: 22px !important;
  left: 10.5% !important;
  opacity: 0.5 !important;
}

.add_list {
  @include widthHeight(92%, 53px);
  @include borderLayoutHeader;
  // margin: 0 auto !important;
  margin: 10px 0px 0px 25px;
  @include flexCenter;
  padding-right: 20px;
}

.section {
  margin-bottom: 20px !important;
  @include text($text-Black-Olive, 14px, normal, 20px, 0);

  &__checks {
    display: flex !important;
    width: 100% !important;
    flex-flow: wrap;
    &__list {
      width: 33.3% !important;
      display: flex;
      height: 40px !important;
      padding-left: 11px !important;
      line-break: anywhere;
    }

    &__table {
      @include text($text-Black-Olive, 16px, normal, 24px, 0);
      white-space: nowrap !important;
    }
  }
}
.section__checks__list.prompt_width {
  width: unset !important;
}

.edit_temp_btn {
  display: flex;
  // justify-content: right;
}

@media only screen and (max-width: 480px) {
  .templates {
    width: 90% !important;
    margin: 0 auto !important;
  }
  .edit_temp_btn {
    display: flex;
    justify-content: space-between !important;
    margin-top: 25px !important;
    padding-left: 70px;
    width: 90% !important;
    // margin: 0 auto;
  }

  .section {
    &__checks {
      flex-direction: column !important;
      &__list {
        width: 100% !important;
      }
    }
  }
}

.accordion_empty {
  font-size: 18px;
  border: none;
  outline: none;
}

.sectionMenu {
  position: absolute !important;
  right: 0%;
  top: 0%;
  // top: -32px !important;
  // left: 78%;
  // top: 18%;
  // right: 2%;
  @include borderLayoutHeader;
  color: $text-Black-Olive;
  min-width: 210px;
  z-index: 6 !important;
  background-color: #ffffff !important;
  &__close {
    font-size: 15px !important;
    margin: 12px 10px 12px 180px;
    float: right !important;
  }
  &__items {
    z-index: 8 !important;
    background-color: #ffffff !important;
    padding: 0 20px 8px 20px !important;
    &__text {
      position: relative;
      background-color: #ffffff !important;
      z-index: 8 !important;
      top: -4px !important;
      left: 13px !important;
      @include text($title-charcoal, 14px, normal, 20px, 0);
    }
  }
}

.colorMenu {
  position: absolute;
  top: 45%;
  right: 0;
  left: 210px;
  background-color: #ffffff !important;
  z-index: 8;
  padding: 16px 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: $text-Black-Olive;
  width: 212px;
  min-width: 160px;
}

.sectionMenu__items__arrow {
  margin-left: 48px !important;
  font-size: 14px !important;
  margin-bottom: 2px !important;
}

.expandIcon {
  position: absolute !important;
  top: 33% !important;
  right: 3% !important;
}

.temprature {
  width: 100% !important;
  @include flexCenter;
}

.EditButton {
  @include widthHeight(136px, 34px);
  @include borderLayoutHeader;
  color: $text-Black-Olive;
  margin: 32px 0px !important;

  &__text {
    margin-left: 6.7px !important;
    @include text($title-charcoal, 13px, 600, 24px, 0.5);
  }
}

.html-comment-editor {
  height: 140px !important;
  -webkit-user-drag: none;
}

.editDescIcon {
  color: #3b3b3b !important;
  font-size: 16px !important;
  margin-top: 1px !important;
  margin-right: 8px !important;
}

.editDescText {
  color: #3b3b3b !important;
}

.editIconGrid {
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.accordionBox {
  border-radius: 4px !important;
  border: 1px solid #e7e7e9 !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.accordionSumDesc .MuiAccordionSummary-content {
  margin: 0px !important;
  width: 100% !important;
}

.accordionTitle {
  color: #3b3b3b !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  border: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  width: 500px;
}

.accordionTitleDisable {
  color: #979797 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  width: 500px;
}
.additional_title_disabled {
  color: #979797 !important;
}
.accordionTitle textarea:focus,
input:focus {
  outline: none;
}

.accordionSumFocus .MuiAccordionSummary-root.Mui-focusVisible {
  background-color: #ffffff !important;
  border: none !important;
}
.te-editor-container .se-submenu.se-list-layer {
  height: 200px !important;
}
.titleDisable {
  color: #979797 !important;
  font-size: 12px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.colorIndicatorsection {
  padding-bottom: 12px !important;
  color: #ac9c9c !important;
  font-weight: 600 !important;
}
.color_title {
  color: #333;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.color_circle {
  width: 16px;
  margin-top: 2px;
  height: 16px;
  border-radius: 50%;
}

@media only screen and (max-width: 680px) {
  .accordionTitle {
    width: 300px !important;
  }
  .accordionTitleDisable {
    width: 300px !important;
  }
  .accordition {
    &__menus {
      width: 12%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .accordionTitle {
    width: 300px !important;
  }
  .accordionTitleDisable {
    width: 300px !important;
  }
}
@media only screen and (max-width: 350px) {
  .accordition {
    &__title__mobile {
      width: 66% !important;
    }
  }
}

.new-comment {
  background-color: $Branding-primary-blue !important;
  color: $secondary-white !important;
  width: 162px !important;
  height: 36px !important;
  padding: 8px 16px 8px 12px !important;
  gap: 4px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  min-width: 158px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}
