@import "../colors/colors";
@import "../mixins/padding";

.report-setting-container {
  padding: 30px 80px;
}

.report-setting-background {
  background-color: $mist-bleach !important;
  min-height: 100vh !important;
}

.report-title {
  font-size: 22px !important;
  margin-top: 10px !important;
  line-height: 30px !important;
  letter-spacing: 0 !important;
  font-style: normal !important;
  color: $title-charcoal !important;
  font-family: "Open sans" !important;
}
.report-title-desc {
  font-size: 14px !important;
  margin-top: 9px !important;
  line-height: 20px !important;
  letter-spacing: 0 !important;
  color: $title-charcoal !important;
  font-family: "Open sans" !important;
}

.report-card-container {
  width: 100% !important;
  margin-top: 24px !important;
  min-height: 708px !important;
  padding: 22px 26px !important;
  border-radius: 4px !important;
  background-color: $secondary-white !important;
  border: 1px solid $secondary-platinum !important;
}

.report-card-container-secondary {
  border-radius: 4px !important;
  background-color: $secondary-white !important;
  border: 1px solid $secondary-platinum !important;
}

.report-preview-title {
  font-size: 11px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: 0 !important;
  font-family: "Open sans" !important;
  text-transform: uppercase !important;
  color: $text-Black-Olive !important;
}

.edit-container {
  padding: 27px 32px 27px 32px !important;
}

.edit-title {
  color: $secondary-Taupe-Gray !important;
  font-family: "Open sans" !important;
  font-size: 12px !important;
  padding: 0px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important; /* 175% */
}

.edit-title-div {
  height: 32px;
  margin-top: 4px;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--spacing-half, 4px);
  border: 1px solid var(--neutral-lt-gray, #e5e8e8);
  background: var(--neutral-white, #fff);
}

.edit-text {
  color: var(--Text-body, #3b3b3b);
  font-family: "Open sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: none !important;
}

.upload-image-container {
  margin-top: 4px;
  border-radius: var(--spacing-half, 4px);
  border: 1px dashed #bebebe !important;
  height: 86px;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Centers children vertically */
  align-items: center; /* Centers children horizontally */
  text-align: center; /* Optional: Centers text inside child elements */
}

.add-logo-text {
  color: #3b3b3b;
  text-align: center;
  font-family: "Open sans" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 183.333% */
  text-transform: none;
}

.add-logo-subtext {
  color: #333;
  font-family: "Open sans" !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  margin-top: -5px;
  line-height: 16px; /* 160% */
  text-transform: none;
}

.upload-icon {
  margin-top: 15px !important;
  width: 31.754px !important;
  height: 22.933px !important;
  color: $Tufts-Blue !important;
  margin-bottom: -5px !important;
}

.report-preview-card-container {
  width: 100%;
  margin-top: 18px !important;
  border-radius: 4px;
  border: 1px solid $Platinum;
  min-height: 527px !important;
  background-color: $sova-grey;
}
.report-preview-cover-image {
  width: 100%;
  height: 291px;
}
.report-preview-logo {
  height: 23px;
  width: 103px;
  margin: 15px 10px 10px 20px;
  object-fit: contain;
}
.report-divider-cover {
  height: 5px !important;
  max-width: 290px;
  margin-top: 30px !important;
  margin-right: 20px !important;
}
.cover-title {
  font-weight: 900 !important;
  margin-top: 15px !important;
  font-size: 30px !important;
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0px !important;
}

.cover-date-container {
  padding: 10px 10px 10px 30px;
}
.cover-date {
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0 !important;
}

.preview-container-left {
  padding: 20px 10px 20px 20px;
}
.preview-container-right {
  padding: 20px 20px 20px 10px;
}
.preview-page-container {
  width: 100%;
  min-height: 497px;
  background-color: $secondary-white;
}

.cover-address {
  font-size: 12px !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  font-family: "Open Sans", sans-serif !important;
}
.cover-detail-title {
  margin-top: 5px !important;
  font-size: 8px !important;
  font-weight: 700 !important;
  color: $Spanish-Gray2 !important;
}
.cover-detail-value {
  margin-top: 2px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0px !important;
  color: $text-Black-Olive !important;
}

.report-company-image {
  height: 20px;
  width: 90px;
  object-fit: contain;
}

.toc-container {
  padding: 20px 15px;
}
.toc-header-right {
  font-size: 8px !important;
  text-align: right;
}
.toc-header-right-client {
  font-size: 9px !important;
  text-align: right;
  color: $Spanish-Gray2 !important;
}

.toc-title {
  height: 25px;
  margin-top: 5px;
  color: $secondary-white;
  width: 100%;
  font-size: 14px;
  padding: 2px 5px;
  font-weight: 600;
  letter-spacing: 0;
}

.table-container {
  padding: 40px 50px;
}
.table-number {
  text-align: right;
}
.footer-left {
  font-size: 8px;
}
.footer-right {
  text-align: right;
  font-size: 9px;
}
.desc {
  color: #333 !important;
  margin-top: 5px !important;
  font-family: "Open sans" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  text-transform: none !important;
}
.checkbox {
  font-family: "Open sans" !important;
  margin-top: 20px !important;
  margin-left: 4px !important;
  margin-right: 5px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  user-select: none;
  line-height: 20px !important;
  color: $title-charcoal !important;
}
.checkbox-desc {
  font-family: "Open sans" !important;
  color: #333 !important;
  font-size: 12px !important;
  user-select: none;
  margin-left: 3px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important; /* 150% */
}

.image-setting {
  height: 63px;
  width: 235px;
  margin-top: 7px;
  object-fit: contain;
}
.report-settings-footer-container {
  margin-top: 52px !important;
  padding-right: 27px !important;
}
.report-settings-footer-save {
  height: 34px !important;
  float: right !important;
  margin-bottom: 8px !important;
  color: $secondary-white !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
  font-size: 13px !important;
  line-height: 24px !important;
  width: 100% !important;
  border-radius: 2px !important;
  background-color: $primary-Jungle-Green !important;
}
.report-settings-footer-save:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}
.report-settings-footer-cancel {
  height: 34px !important;
  float: right !important;
  margin-right: 20px !important;
  color: $secondary-Gray !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  font-size: 13px !important;
  line-height: 24px !important;
  width: 101px !important;
  border: 1px solid $secondary-Gray !important;
  border-radius: 2px !important;
  background-color: $secondary-white !important;
}
.report-usenameaslogo {
  font-size: 14px !important;
  font-family: 'Open sans' !important;
  font-weight: 600 !important;
  margin: 20px 2px 10px 12px !important;
}
.toc-usenameaslogo {
  font-family: 'Open sans' !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.selected-image-conatainer-report {
  height: 84px;
  width: 255.24px;
  position: relative;
  margin: 17px 0px 13px 0px;
  border: 0.67px solid #cccccc;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 11px 8px 10px 9px;
}
.selected-image-top-left {
  width: 9px;
  height: 9px;
  position: absolute;
  border-right: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
}
.selected-image-bottom-left {
  width: 9px;
  height: 9px;
  margin-top: -10px;
  position: absolute;
  border-right: 1px solid #a0a0a0;
  border-top: 1px solid #a0a0a0;
}
.selected-image-top-right {
  width: 9px;
  height: 9px;
  position: absolute;
  right: 10px;
  border-left: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
}
.selected-image-bottom-right {
  width: 9px;
  height: 9px;
  position: absolute;
  margin-top: -10px;
  right: 10px;
  border-top: 1px solid #a0a0a0;
  border-left: 1px solid #a0a0a0;
}
.report-logo-edit-button {
  padding: 0px !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  color: #4282e2 !important;
  float: right;
  font-weight: 600 !important;
  margin-right: 30px !important;
}
@media only screen and (max-width: 900px) {
  .report-setting-container {
    padding: 20px 23px;
  }
  .preview-container-left {
    padding: 20px 10px;
  }
  .preview-container-right {
    padding: 20px 10px;
  }
  .report-divider-cover {
    max-width: 290px;
    height: 5px !important;
    margin-top: 30px !important;
    margin-right: 20px !important;
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .report-preview-cover-image {
    width: 100%;
    height: 200px;
  }
}
