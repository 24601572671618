@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/fonts";

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-Regular.ttf") format("opentype");
}

.inspections-main {
  padding-top: 25px !important;
  // padding: 80px;
  padding-right: 80px;
  padding-left: 80px;
  padding-bottom: 80px;
}

.to-right {
  display: flex !important;
  justify-content: right !important;
  gap: 10px;
}

@media screen and (max-width: 550px) {
  .inspections-main {
    padding: 0px;
  }

  .inspections-head {
    padding: 15px;
    margin-bottom: -85px !important;
  }

  .inspections-filter-toolbar {
    overflow: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .inspections-filter-toolbar::-webkit-scrollbar {
    display: none;
  }

  .inspection-header {
    padding: 0px;
  }

  .inspection-title {
    padding-top: 8px;
  }

  .inspection-search-field {
    height: 55px !important;
  }

  .inspection-search-icon {
    font-size: 35px !important;
  }

  .inspection-filter-button {
    height: 55px !important;
  }
  .inspections-subheader {
    display: none !important;
  }

  .inspections-filter-toolbar {
    // margin-top: 15px;
    margin-bottom: 34px;
  }
}
@keyframes pulse {
  0% {
    border-width: 2px;
    border-color: red;
  }
  20% {
    border-width: 2.5px;
    border-color: red;
  }
  50% {
    border-width: 3px;
    border-color: red;
  }
  80% {
    border-width: 3px;
    border-color: red;
  }
  100% {
    border-width: 2px;
    border-color: red;
  }
}

@keyframes colorChange {
  0% { color: black; }
  50% { color: rgb(0, 195, 255); }
  100% { color: black; }
}

.animated-text {
  animation: colorChange 4s infinite;
}

.pulsing-border {
  border: 2px solid red;
  z-index: 10;
  color: #e30303 !important;
  border-radius: 5px;
  animation: ease-in-out 2s ease-in-out infinite;
  opacity: 1;
}

.border-image-clip-path {
  width: 100%;
  // height: 100px;
  border: 3px solid;
  border-image: linear-gradient(45deg, rgb(0, 234, 255), rgb(20, 255, 196)) 1;
  clip-path: inset(0px round 5px);
  animation: huerotate 6s infinite linear;
  filter: hue-rotate(360deg);
  color: blue !important;
}

@keyframes huerotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.box {
  width: 100%;
  // height: 100px;
  background-color: red;
  position: relative;
  animation: highlight 3s yellow;
}

// @keyframes easeOutAnimation {
//   0% {
//       transform: translateY(0);
//   }
//   100% {
//       transform: translateY(200px);
//   }
// }

// @media screen and  (max-width:900px) {
//   .insp-date_time > div > span {
//     position: relative !important;
//     left: 7px !important;
// }

// .inspection-table-head:nth-child(4) {
//   position: relative !important;
//   right: 0px !important;
// }

// .insp-client > span {
//   left: 34px !important;
// }

// .inspection-table-head:nth-child(5) {
//   left: 30px !important;
// }

// .insp-id > span {
//   position: relative;
//   left: 35px !important;

// }

// .inspection-table-head:nth-child(6) {
//   left: 27px !important;
// }

// .insp-agent > span {
//   left: 66px !important;
// }

// .inspection-table-head:nth-child(7) {
//   left: 21px !important;
// }

// .insp-inspector > span {
//   left: 78px !important;
// }

// .inspection-table-head:nth-child(8) {
//   left: 0px !important;
// }

// .insp-inspector > span {
//   padding-right: 77px !important;
// }

// .inspection-table-head:nth-child(9) {
//   left: 5px !important;
// }

// .inspection-table-head:nth-child(10) {
//   left: 5px !important;
// }

// .insp-file-icon > div {
//   padding-right: 25px !important;
// }

// .inspection-table-head:nth-child(11) {
//   left: 0px !important;
// }

// .inspection-table-head:nth-child(12) {
//   left: 0px !important;
// }

// .insp-fee > div {
//   padding-right: 20px !important;
// }

// .insp-updated > div {

//   padding-right: 35px !important;
// }

// .insp-dollar > div {

//   padding-right: 27px !important;
// }
// }

.mobileScroller {
  transform: rotate(180deg);
  overflow-x: scroll;
}

.windowScroller {
  overflow-x: unset !important;
  overflow: hidden;
}

.mobileScroller::-webkit-scrollbar {
  height: 6px;
  width: 20px;
}

.mobileScroller::-webkit-scrollbar-trackr {
  background-color: orange;
}

.mobileScroller::-webkit-scrollbar-thumb {
  background-color: $text-Black-Olive;
  border-radius: 4px;
}

@media screen and (max-width: 380px) {
  .inspections-new-button {
    left: 5px !important;
  }

  // .insp-id{
  //     padding-left: 15px;
  // }

  .inspection-header {
    width: auto;
    padding: 0px;
  }

  .inspection-title {
    position: relative;
    left: -40px;
    padding-left: 40px;
  }
}

.inspections-export-content {
  display: flex;
  position: relative;
  top: 10px;
  margin-left: 15px;
  color: $secondary-Tufts-Blue;
  cursor: pointer;
}

.inspection-search-icon {
  color: $text-Black-Olive;
}

.inspections-export-icon {
  color: $secondary-Tufts-Blue;
  margin-right: 5px;
}

.inspections-head {
  margin-bottom: -55px;
  position: relative;
  left: 36px;
  width: calc(100% - 23px) !important;
}

.inspection-toolbar-main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px !important;
}

.inspection-title {
  height: 30px;
  width: auto;
  color: $title-charcoal;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
  line-height: 30px;
}

.inspections-filter-toolbar {
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.inspections-filter-toolbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.inspections-subheader {
  height: 20px;
  width: auto;
  margin-top: 4px;
  color: $text-Black-Olive;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  display: inline-block;
}

.insp-details > span {
  position: relative;
  //   left: 15px;
  font-size: 13px !important;
}

.inspections-data-table > td {
  font-size: 13px !important;
  white-space: nowrap;
  color: $title-charcoal !important;
}

.inspection-header {
  display: flex;
  justify-content: space-between;
  // margin-bottom: -76px;
  align-items: center;
  margin: 0px 23px 18px 23px;
}
.edit-icon {
  color: #3b3b3b;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
.edit-icon.disabled {
  color: $text-Black-Olive;
  opacity: 0.25;
  &:hover {
    cursor: pointer;
    opacity: 0.25;
  }
}
.delete-icon {
  color: #e30303;
  opacity: 0.25;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
.delete-icon-TE {
  color: #3b3b3b;
  opacity: 0.8;
  &:hover {
    color: #e30303;
    cursor: pointer;
    opacity: 1;
  }
}
.edit-icon-TE {
  color: #3b3b3b;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
// .MuiInputBase-input{
//   color: $Silver-Sand !important;
// }

.inspections-new-button {
  //   float: right !important;
  // border: 1px solid $button-border !important;
  border: none !important;
  // border-radius: 4px !important;
  color: $secondary-white !important;
  border-radius: 2px !important;
  background-color: $primary-Jungle-Green !important;
  box-shadow: none !important;
  width: 119px !important;
  text-transform: none !important;
  height: 34px !important;
  position: relative !important;
  top: 10px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 0.04px !important;
  line-height: 24px !important;
  cursor: pointer;
  &:hover {
    background: #1e8970 !important;
  }
}

.add-template-library-button {
  //   float: right !important;
  // border: 1px solid $button-border !important;
  border: none !important;
  // border-radius: 4px !important;
  color: $secondary-white !important;
  border-radius: 2px !important;
  background-color: $primary-Jungle-Green !important;
  box-shadow: none !important;
  width: 159px !important;
  text-transform: none !important;
  height: 34px !important;
  position: relative !important;
  top: 10px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 0.04px !important;
  line-height: 24px !important;
  cursor: pointer;
  &:hover {
    background: #1e8970 !important;
  }
}

.inspections-new-button2 {
  // border: 1px solid $button-border !important;
  border: none !important;
  color: $secondary-white !important;
  border-radius: 4px !important;
  background-color: $primary-Jungle-Green !important;
  box-shadow: none !important;
  width: 148px !important;
  text-transform: none !important;
  height: 48px !important;
  position: relative !important;
  top: 10px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  letter-spacing: 0.04px !important;
  line-height: 24px !important;
  &:hover {
    background: #1e8970 !important;
  }
}

.MuiTablePagination-select {
  font-family: $OpenSans_Regular !important;
  color: $text-Black-Olive !important;
  font-size: 12px !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-size: 12px !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  font-size: 12px !important;
}

.table-body-row {
  padding: 12px 0px;
  // width: 215px;
  font-size: 13px !important;
  display: flex;
}

.pagination {
  font-family: $OpenSans_Regular !important;
}

.table-body-row > p {
  font-size: 13px !important;
  color: $title-charcoal;
}

.insp-date_time > div > span {
  font-size: 13px !important;
  position: relative;
  left: 5px;
}

.insp-date_time > div > span:nth-child(2) {
  font-size: 13px !important;
  position: relative;
  left: 3px;
}

.table-body-row-img {
  width: 52px;
  height: 52px;
  border-radius: 5px;
  margin-top: 5px !important;
  // margin-right: 20px;
}

.date-placeholder {
  display: block;
  font-size: 13px !important;
  color: $title-charcoal;
  letter-spacing: 0;
  line-height: 20px;
}

.time-placeholder {
  color: $title-graphite;
  text-transform: uppercase;
  font-size: 12px !important;
}

.time-modified-placeholder {
  color: $title-graphite;
  font-size: 10px;
  text-transform: none;
}

.inspection-table-head {
  //  position: relative;
  // right: 6px;
  max-width: 200px;
  color: #9a9a9a;
  font-size: 12px;
  line-height: 19.14px;
  padding-right: 10px;
  &:last-child {
    display: flex;
  }
}

.insp-address {
  // width: 10px;
  cursor: pointer !important;
  position: sticky;
}
.insp-list-tbody {
  td {
    padding-left: 15px;
  }
}
.inspection-list-header {
  td:first-child {
    text-align: center;
  }
}

// .inspection-table-head:nth-child(2){
//   position: relative;
//   right: 15px;
// }

// .inspection-table-head:nth-child(4){
//   position: relative;
//   right: 13px;
// }

// .inspection-table-head:nth-child(6){
//   position: relative;
//   right: 15px;
// }

// .inspection-table-head:nth-child(7){
//   position: relative;
//   right: 3px;
// }

// .insp-updated > div {
//   position: relative;
//   left: 30px;
//   padding-right: 10px;

// }

// .insp-file-icon > div{
//   position: relative;
//   left: 25px;
// }

// .insp-dollar > div {
//   position: relative;
//   left: 24px;
// }

// .insp-fee > div {
//   position: relative;
//   left: 22px;
// }

.insp-inspector > span {
  // position: relative;
  // left: 30px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  // padding-right: 10px;
}

.insp-agent > span {
  // position: relative;
  // left: 28px;
  display: flex;
  line-height: 20px;
  flex-direction: column;
  white-space: pre-wrap;
  // padding-right: 10px;
}

.insp-client > span {
  // position: relative;
  white-space: pre-wrap;
  // left: 24px;
  display: flex;
  line-height: 20px;
  flex-direction: column;
  color: $title-charcoal !important;
  // padding-right: 10px;
}

.insp-id > span {
  color: $title-charcoal !important;
  // position: relative;
  // left: -18px;
}

// .insp-status > div {
//   position: relative;
//   left: 8px;
// }

// .insp-status{
//   padding-right: 10px;
// }

.insp-actions > span {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  text-transform: none !important;
  // left: -15px;
}

.inspection-filter-button {
  width: 83px !important;
  height: 40px;
  border-color: $secondary-Gray !important;
  margin-left: 15px !important;
  color: $text-Black-Olive !important;
  font-size: 11px !important;
}

.inspection-filter-button-icon {
  font-size: 18px !important;
}

.inspection-search-field {
  width: 280px;
  height: 40px;
  border: 1px solid $secondary-Gray;
  border-radius: 3px !important;
  box-shadow: none !important;
  color: $text-Black-Olive !important;
}

.inspection-filter-modal-main {
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: hidden;
    width: 2px;
  }
}

.inspection-filter-modal-main1 {
  overflow-x: hidden;
  overflow-y: auto;
}

.edit-category-outer-modal {
  overflow-x: hidden;
  overflow-y: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inspection-filter-modal {
  position: relative;
  top: 50%;
  left: 50%;
  margin-bottom: 20%;
  transform: translate(-50%, -25%);
  max-width: 970px;
  border-radius: 2px;
  background-color: $secondary-white;
  // border: 2px solid $secondary-platinum;
  padding: 4px;
  outline: 0;
}

.edit-category-modal {
  max-width: 970px;
  border-radius: 5px;
  background-color: $secondary-white;
  border: 2px solid $secondary-platinum;
  padding: 4px;
  outline: 0;
}

.inspection-filter-modal-grid-main {
  padding: 30px;
}

.inspection-filter-modal-title {
  height: 30px;
  width: 350px;
  color: $title-charcoal;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: normal;
  margin-left: 15px !important;
}

.inspection-filter-modal-close-icon {
  float: right;
  position: relative;
  top: -25px;
  right: -25px;
  cursor: pointer;
}

.inspection-table-main {
  position: relative;
  left: 50%;
  // min-height: 708px;
  transform: translate(-50%, 0%);
  padding: 25px 18px !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-family: "Open Sans", sans-serif !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  font-family: "Open Sans", sans-serif !important;
}

// .css-i4bv87-MuiSvgIcon-root {
//   height: 23px !important;
//   // border: 1px solid $secondary-Silver-Sand ;
// }

.inspection-filter-modal-select {
  max-width: 400px !important;
  // border: 1px solid $secondary-Silver-Sand ;
}

.auto-complete-basedon {
  display: none !important;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px;
}

// .css-k4qjio-MuiFormHelperText-root{
//   font-size:0.9rem !important;
//   margin-left: 8px !important;
//   color: #E30303;
// }

.custom-color .MuiButtonBase-root.MuiChip-root {
  border-radius: 3px !important;
  color: $Green-Crayola !important;
  border: 1px solid $Green-Crayola !important;
  background-color: transparent !important;
  position: relative;
  height: 30px !important;
  &::after {
    content: "";
    border-left: 1px solid $Green-Crayola;
    height: 28px;
    position: absolute;
    right: 26px;
  }
}
.custom-color .MuiChip-label {
  margin-right: 9px !important;
  color: $Green-Crayola !important;
}
.custom-color .MuiSvgIcon-root.MuiChip-deleteIcon {
  color: $Green-Crayola !important;
  font-size: 14px;
  margin-right: 6px;
}
.custom-color .MuiAutocomplete-clearIndicator {
  svg {
    font-size: 14px !important;
  }
}
.custom-color .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 5px !important;
  // height: 40px!important;
}

// .inspection-filter-modal-date-range {}

.inspection-filter-modal-label {
  height: 21px;
  width: 130.35px;
  color: $primary-Sonic-Silver;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
}

.inspection-filter-title {
  padding-bottom: 18px;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: bold !important;
  color: $text-Black-Olive !important;
}

.css-1gsv261 {
  border-bottom: none !important;
}

.inspection-filter-modal-margin {
  margin-left: 15px !important;
}

.inspection-filter-modal-margins {
  margin-left: 15px !important;
  display: flex;
}

.inspection-filter-modal-marginsout {
  margin-left: 15px !important;
  display: flex;
  height: 350px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: block !important;
  }
}
.inspection-filter-modal-marginsout.colFlex {
  flex-direction: column !important;
}

.inspection-filter-modal-margin1 {
  margin-left: 15px !important;
  width: 58% !important;
}

.inspection-filter-modal-margin2 {
  margin-left: 15px !important;
  width: 100% !important;
}

.css-j204z7-MuiFormControlLabel-root {
  margin-right: 32px !important;
}

.inspections-filter-modal-tabs {
  border-radius: 5px;
  border: 1px solid $secondary-Silver-Sand;
  width: fit-content;
  min-height: 32px !important;
  border-bottom: 1px solid $secondary-Silver-Sand;
  margin-left: 15px !important;
}

.inspections-filter-modal-tab {
  font-weight: 600 !important;
  border-right: 1px solid $secondary-Silver-Sand !important;
  font-size: 14px !important;
  min-height: 32px !important;
  width: 137px;
  text-transform: none !important;
}

.inspection-filter-modal-buttons {
  margin-top: 68px !important;
  display: flex !important;
  justify-content: flex-end;
}

.inspection-filter-modal-buttons > button {
  height: 34px !important;
  width: 101px !important;
  margin-right: 15px;
}

.inspection-filter-modal-buttons1 {
  margin-top: 68px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.inspection-filter-modal-buttons1 > div > button {
  height: 34px !important;
  width: 101px !important;
  margin-right: 15px;
}

.delete_template_btn {
  margin-left: 6% !important;
  padding: 5px 33px 5px 33px !important;
  border: 1px solid red !important;
  color: red !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.inspection-filter-modal-buttons-cancel {
  border: 1px solid $button-border !important;
  color: $primary-Spanish-Gray !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.inspection-filter-modal-buttons-apply {
  // border: 1px solid $button-border !important;
  color: $secondary-white !important;
  background-color: $primary-Jungle-Green !important;
  // border-radius: 2px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.inspection-filter-modal-buttons-apply:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}

.inspection-filter-chips {
  margin-left: 15px !important;
  background-color: $secondary-Tufts-Blue !important;
  height: 26px !important;
  border-radius: 16px !important;
  color: $secondary-white !important;
  font-size: 12px !important;
  position: relative;
  top: 10px;
}

.inspection-filter-chip-reset {
  margin-top: 2px !important;
  margin-left: 30px !important;
  color: $secondary-Tufts-Blue;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  top: 10px;
}

.inspection-filter-chips-icon {
  color: $secondary-white !important;
  opacity: 70% !important;
}

.inspections-data-table {
  position: relative;
}

.inspections-data-table:nth-child(odd) {
  background-color: $secondary-cultered;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $secondary-Light-Grey;
  box-shadow: inset 0 0 6px $secondary-Light-Grey;
  border-radius: 10px;
  background-color: $secondary-Ghost-White;
}

// div::-webkit-scrollbar {
//   height: 2px;
//   background-color: $secondary-Gray;
// }

div::-webkit-scrollbar:hover {
  height: 2px;
  background-color: $secondary-Arsenic;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px $secondary-Light-Grey;
  box-shadow: inset 0 0 6px $secondary-Light-Grey;
  background-color: $secondary-Light-Grey;
}
.subscribe span {
  font-weight: 600;
  color: $primary-Jungle-Green;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline;
  margin: auto;
  cursor: pointer;
}
.subscription__alert {
  position: relative !important;
  top: 10px !important;
  background: #fff2e0 !important;
  color: #ff9800 !important;
  line-height: 22px !important;
  div {
    overflow: hidden !important;
  }
  .subscription__alert.owner {
    height: 67px !important;
  }
}
@media only screen and (max-device-width: 480px) {
  .inspection-filter-modal {
    max-width: 970px;
    border-radius: 5px;
    background-color: $secondary-white;
    border: 2px solid $secondary-platinum;
    padding: 4px;
    outline: 0;
  }

  .inspection-filter-modal-grid-main {
    padding: 5px;
  }

  .inspections-filter-modal-tab {
    width: min-content !important;
  }

  .inspection-filter-modal-margins {
    // flex-direction: column !important;
    margin-left: 15px;
  }

  .inspection-filter-modal-main {
    overflow: hidden;
    overflow-y: scroll;
  }

  .inspection-filter-modal-main::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.arrow-style .MuiButtonBase-root svg {
  margin-top: 3px !important;
}

.pagination-inspection .MuiTablePagination-toolbar p {
  font-size: 1rem !important;
}


.autocompleteCustom {
  .MuiButtonBase-root:nth-child(1) {
    height: 24px !important;
    padding: 2px 2px 2px 10px !important;
  }

  .customSearch {
    position: absolute;
    left: 5px;
  }
  .customSearch.hidden {
    display: none !important;
  }

  .MuiCheckbox-root input {
    border-radius: 5px !important;
  }

  .customArrowDown {
    position: absolute;
    right: 12px;
    color: #637083;
    cursor: pointer !important;
    top: 13px !important;
    font-size: 14px !important;
  }
  .customArrowDown.arrowActive {
    rotate: 180deg;
  }

  input:hover {
    cursor: pointer !important;
  }
  .MuiButtonBase-root {
    background-color: #66A3E3;
    cursor: pointer;
    color: $secondary-white !important;
    max-height: 24px;
  }
  .MuiChip-deleteIcon {
    color: $secondary-white !important;
    background-color:rgba(0, 0, 0, 0.4) !important;
    border-radius: 50%;
    font-size: 16px !important;
    padding: 2px !important;
    margin-right: 4px !important;
  }
  .Mui-checked {
    color: $Tufts-Blue !important;
  }
}

.autoHover {
  &:hover {
    cursor: pointer !important;
    background: #F8F8F8;
  }

  input:hover {
    cursor: pointer !important;
  }
}
