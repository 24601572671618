@import '../colors/colors';
@import '../mixins/padding';

.change_password_background {
    background-color: $Light-Grey;
    min-height: 100vh;
}
.change_password_card {
    margin-top: 100px !important;
    box-shadow: 0 20px 20px 0 $primary-shadow !important;
}
.change_password_grid {
    display: flex;
    justify-content: left;
}
.change_password_header {
    color: $title-charcoal;
    text-transform: none;
    letter-spacing: 0 !important;
    user-select: none;
    line-height: 20px !important;
    margin: 27px 0 10px 0 !important;
    padding-left: 30px !important;
}
.change_password_confirm_button {
    color: $secondary-white !important;
    font-weight: 600 !important;
    border-radius: 2px !important;
    font-size: 13px !important;
    font-family: "Open Sans", sans-serif !important;
    text-transform: none !important;
    background-color: $primary-Jungle-Green !important;
    letter-spacing: 0.44px !important;
}
.change_password_confirm_button:hover {
    background-color: $secondary-Celadon-green !important;
}
.change_password_confirm_button:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
}
