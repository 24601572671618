$primary-Sonic-Silver: #777777;
$primary-Spanish-Gray: #9b9b9b;
$primary-Jungle-Green: #239d80;
$primary-steeper-Green: #1f9c7b;
$Green-Crayola: #0ead7c;
$primary-linear-background: linear-gradient(180deg, rgba(35, 157, 128, 0.93) 0%, rgba(35, 83, 157, 0.93) 100%);
$primary-Platinum: #eae7e3;
$primary-shadow: rgba(40, 40, 40, 0.4);
$Branding-primary-blue: #0f75bc;
$secondary-Gray: #bebebe;
$secondary-Smoke-White: rgb(229, 229, 229);
$secondary-Ghost-White: #fbf9f6;
$secondary-Silver-Sand: #c4c4c4;
$secondary-Celadon-green: #1e8970;
$secondary-jungle-green: #2bab8c;
$secondary-Tufts-Blue: #4282e2;
$secondary-Light-Grey: #dad7d0;
$secondary-Arsenic: #3d454d;
$secondary-Taupe-Gray: #888888;
$secondary-linear-background: linear-gradient(180deg, #239d80 0%, #23539d 100%);
$background-ghost-white: #faf8f7;
$text-Black-Olive: #3b3b3b;
$background-Cultured: #f5f6f8;
$text-egyptian-blue: #1f2f9c;
$text-lavender-blue: #c7dbf6;
$background-lemon-chiffon-color: #fff9c8;
$background-lavender: #f1ddff;
$title-graphite: #6d7278;
$title-charcoal: #333333;
$white-smoke: #f5f5f5;
$secondary-black: #000000;
$secondary-platinum: #e7e7e9;
$secondary-white: #ffffff;
$transparant-black: #00000033;
$severity-Electric-Red: #e30303;
$button-border: #d8d8d8;
$secondary-cultered: #f7f7f7;
$Spanish-Gray: #9b9b9b;
$Cultured: #f5f5f5;
$Silver-Sand: #c3c3c3;
$Spanish-Gray2: #9a9a9a;
$Tufts-Blue: #4282e2;
$Platinum: #e3e3e3;
$Bright-Gray: #eeeeee;
$Old-Lace: #fff2e0;
$Dark-Charcoal: #323232;
$Dark-Charcoal2: #333333;
$Green-Crayola: #1f9c7b;
$Light-Grey: rgba(0, 0, 0, 0.51);
$Mountain-Mist: #979797;
$Cyan-Blue: #eaf5ff;
$Lime-Gray: #ededed;
$border-grey: #d5d5d5;
$mist-bleach: #f7f9fa;
$sova-blue: #32325d;
$light-blue: #8898aa;
$sova-grey: #f5f5f6;
$sova-green: #c7f6e0;
$sova-light-grey: #f9f9f9;
$sova-red: #f44336;
$Dark-jungle: #1c1b1fd9;
$GunSmoke: #878787;
$Pattens-Blue: #e6f1ff;
$Outer-Grey: #637083;
$secondary-Outer-Grey: #ced2da;
$Outer-Blue: #0b588e;
$secondary-grey: #a5a5a5;
$Dark: #252525;
$Sonic-Silver: #757575;
$Santa-Grey: #a5a5a5;
$Oxford-Blue: #09244b;
$Cool-Grey: #8491a5;
$Silver-Sand: #b5bdc9;
$Charcoal-Grey: #424242;
$Outer-Space: #454545;
$Anti-Flash-White: #f3f4f5;
$CulturedF8: #f8f8f8;
$tich-black: #111111;
$Cyan-blue-light: #eef5ff;
$Branding-primary-blue: #0f75bc;
$Sonic-Silver: #757575;
$Santa-Grey: #a5a5a5;
$Oxford-Blue: #09244b;
$Branding-primary-blue: #0f75bc;
$Outer-Space: #454545;
$Anti-Flash-White: #f3f4f5;
$CulturedF8: #f8f8f8;
$tich-black: #111111;
$Cyan-blue-light: #eef5ff;
:root {
  --default-primary-color: #4282e2;
}
$primary-color: var(--default-primary-color);
$Branding-primary-blue: #0f75bc;
