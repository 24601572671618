@import "../colors/colors";
@import "../mixins/index";

.library-container {
  flex-grow: 1 !important;
  background-color: #fff !important;
  min-height: 100vh !important;
}

.add-new-btn {
  height: 36px !important;
  width: 130px !important;
  background-color: #0062a0 !important;
  color: #fff !important;
  font-weight: 900 !important;
  border-radius: 4px !important;
  float: right !important;
  text-transform: none !important;
}
.add-new-btn span {
  @include text(#fff, 14px, 600, 20px, normal);
}
.add-new-btn:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
  border: 1px solid $secondary-Smoke-White !important;
}

.options {
  height: 36px !important;
  width: 100px !important;
  background-color: #fff !important;
  color: #757575 !important;
  border-radius: 4px !important;
  float: right !important;
  border: 2px solid #d5d5d5 !important;
}
.options span {
  @include text(#757575, 14px, 600, 20px, normal);
}

.date-placeholder-comment {
  display: block;
  font-size: 14px !important;
  color: $title-charcoal;
  letter-spacing: 0;
  line-height: 20px;
}

@media (max-width: 1200px) {
  /* Adjust margin-right when viewport width is below 1200px */
  .date-placeholder-comment {
    display: block;
    font-size: 14px !important;
    color: $title-charcoal;
    letter-spacing: 0;
    line-height: 20px;
    margin-right: 100px;
  }
}
.cl-table-container {
  overflow-y: scroll !important;
  height: calc(100vh - 232px - 100px) !important;
}

.cl-table.MuiPaper-elevation.MuiPaper-rounded {
  max-width: 100% !important;
  height: 100% !important;
}

.dialog-content-comment-library {
  margin-top: 2px;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 5px !important;
  .input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 8px;

    span {
      color: $severity-Electric-Red;
    }
  }
  .comment-input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 6px;
  }
}

.tab_panel_cLibrary .MuiBox-root {
  padding: 0px !important;
}

.comment-library-action {
  padding-left: 34px !important;
  padding-right: 28px !important;

  .cl-cancel-btn {
    width: 101px !important;
    height: 34px !important;
    @include text($Spanish-Gray, 13px, 600, 24px, normal);
    border: 1px solid $button-border;
    border-radius: 1px !important;
    text-transform: capitalize !important;
    letter-spacing: 0.5px !important;
  }

  .cl-add-btn {
    width: 170px !important;
    height: 34px !important;
    background: $Tufts-Blue !important;
    @include text($secondary-white, 13px, 600, 24px, normal);
    border: 1px solid $Tufts-Blue;
    border-radius: 1px !important;
    text-transform: unset !important;
    letter-spacing: 0.5px !important;
  }
  .cl-add-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
    border: 1px solid $secondary-Smoke-White;
  }
}
.comment-library-summary-action {
  padding-left: 34px !important;
  padding-right: 28px !important;

  .cl-summary-cancel-btn {
    cursor: pointer;
    width: 64px !important;
    height: 36px !important;
    @include text($Branding-primary-blue, 13px, 600, 24px, normal);
    border: none;
    background-color: transparent !important;
    margin-right: 8px !important;
    border-radius: 1px !important;
    text-transform: capitalize !important;
    letter-spacing: 0.5px !important;
  }

  .cl-add-summary-btn {
    cursor: pointer;
    width: 64px !important;
    height: 36px !important;
    background: $Tufts-Blue !important;
    @include text($secondary-white, 13px, 600, 24px, normal);
    border: 1px solid $Tufts-Blue;
    border-radius: 3px !important;
    text-transform: unset !important;
    letter-spacing: 0.5px !important;
  }
  .cl-add-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
    border: 1px solid $secondary-Smoke-White;
  }
}

.cl-summary-sort-icon {
  font-size: 24px !important;
  color: $Santa-Grey !important;
}

.cl-summary-table-head,
.cl-summary-table-head span {
  @include text($title-charcoal, 14px, 600, 20px, Source Sans Pro);
}

.styled-tab,
.styled-tab span {
  @include text(inherit, 14px, 600, 20px, normal);
}

.cl-table-exp-icon {
  rotate: 90deg;
  color: $Mountain-Mist;
  margin-left: 15px;
}

.cl-row-status {
  button {
    padding: 0px !important;
  }
}

.cl-search {
  position: relative;

  .MuiOutlinedInput-root {
    padding-left: 26px !important;
    border-radius: 4px !important;
  }

  button {
    padding-right: 0px;
  }

  background-color: $secondary-white;

  & > div:first-child {
    width: 100%;
  }

  .cl-summary-search-icon {
    position: absolute;
    left: 10px;
    top: 6px;
    cursor: pointer;
    font-size: 24px !important;
    color: #757575 !important;
  }
}

.cl-search-container {
  padding: 20px 0px 10px 0px !important;
}

.cl-title-col {
  width: 142px !important;
  @include text($Outer-Space, 12px, 400, 20px, normal);
  vertical-align: text-top !important;
}

.cl-comment-col {
  width: 600px !important;
  vertical-align: text-top !important;
}
.cl-summary-col {
  width: 248px !important;
  vertical-align: text-top !important;
}
.cl_rating_square_container {
  max-width: 126px !important;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  .rating_square {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    font-size: 8px;
    color: $secondary-white;
  }

  .cl_rating_name_text {
    @include text($Outer-Space, 12px, 600, 15px, normal);
  }
}
.cl-rating-col {
  min-width: 50px !important;
  vertical-align: text-top !important;
}
.cl-times-used-col {
  min-width: 50px !important;
  vertical-align: center !important;
}
.cl-exp-col {
  padding-top: 8px !important;
  vertical-align: text-top !important;
}
.cl-checkbox-col {
  vertical-align: text-top !important;
}
.cl-checkbox-col.cl-check-active {
  border-left: 1px solid $Tufts-Blue;
}
.cl-comment-col,
.cl-summary-colS {
  position: relative;
}
.cl-color-col {
  width: 200px !important;
  svg {
    color: $tich-black !important;
  }
}

.cl-times-used-col {
  width: 190px !important;
  svg {
    color: $Sonic-Silver !important;
  }
}
@media (min-width: 1024px) {
  .cl-comment-col {
    width: 650px !important;
    vertical-align: text-top !important;
  }
  .cl-summary-col {
    width: 500px !important;
  }
  .cl-rating-col {
    min-width: 170px !important;
    vertical-align: text-top !important;
  }
  .cl-times-used-col {
    min-width: 170px !important;
    vertical-align: center !important;
  }
}

.comment-text {
  @include text($text-Black-Olive, 14px, 400, 22px, normal);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 545px !important;
}

.comment-text.expanded {
  overflow: visible;
  display: block;
}

.comment-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.comment-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.comment-text.expanded::after {
  display: none;
}

.comment-text select,
.summary-text select {
  height: 22px;
  appearance: none;
  padding: 2px 6px !important;
  border: 1px solid #d7d7d7 !important ;
  background: #f7f7f7;
  border-radius: 4px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 200px;
  min-width: 100px;
}
.comment-text select option,
.summary-text select option,
.narrative_summary_content select option,
.comment_description_cl select option {
  background-color: #fff !important;
}
.comment-text select option:hover {
  background-color: #000 !important;
}
.comment-text.expanded select,
.summary-text.expanded select,
.narrative_summary_content select,
.summary-text {
  @include text($text-Black-Olive, 14px, 400, 22px, normal);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 274px !important;
}

.summary-text select option:hover {
  background-color: #000 !important;
}

.summary-text.expanded {
  overflow: visible;
  display: block;
}

.summary-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.summary-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.summary-text.expanded::after {
  display: none;
}

.loader-container-comment-library {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 425px !important;
  max-height: 600px;
}

.comment_library_upload svg {
  margin-right: 10px;
  font-size: 16px;
}

.comment_library_upload {
  border: 1px solid $primary-Sonic-Silver !important;
  color: $primary-Sonic-Silver !important;
  text-transform: none !important;
}

.cl-tags_button_group_container {
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 68px;
  display: flex;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  position: absolute;
  background-color: #fff;
  top: 10px;
  right: 10px;
}

.button_group_tab {
  width: 36px;
  height: 32px;
  display: flex;
  justify-content: center;
  border-radius: 3px !important;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d5d5d5;
}

.button_group_tab:hover {
  background-color: #d5d5d5 !important;
}

.button_group_delete {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_group_delete:hover {
  background-color: #d5d5d5 !important;
}

.times-used-text {
  @include text($Outer-Space, 14px, 400, 16px, normal);
}

.hex-color-title-text {
  @include text($tich-black, 16px, 400, 16px, normal);
  letter-spacing: 0px;
  padding-top: 1px;
}

.summary-text-field div input {
  height: 5px !important;
}

.color-picker-container div {
  min-height: 10px !important;
  min-width: 0px !important;
  box-shadow: none !important;
}

.popper-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.add-summary-color-picker > div:first-child {
  height: 334.23px !important;
}
.edit-summary-color-picker > div:first-child {
  height: 300.23px !important;
}
.edit-summary-color-picker {
  padding: 16px !important;
}
.add-summary-color-picker {
  padding: 16px !important;
}
.cl-comment-title {
  color: $Outer-Space !important;
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.cl-comment-title.expanded {
  overflow: visible;
  display: block;
}

.cl-comment-title.expanded {
  p:nth-child(2) {
    display: none;
  }
  p:nth-child(1) {
    display: block;
  }
}

.cl-comment-title.expanded::after {
  display: none;
}

.comment_tags_container {
  width: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

.comment_tag {
  height: 18px;
  border-radius: 4px;
  padding: 3px 8px 0px 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13.62px;
}

.cl_tags_textfield div input {
  width: 450px !important;
  background-color: #fff !important;
  border-color: "#E5E8E8" !important;
  height: 3px !important;
  font-family: "Open sans" !important;
}

.inspections-filter-toolbar {
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ci_title {
  font-family: "Source Sans Pro", sans-serif !important;
  @include text(#333, 24px, 600, 32px, 0px);
}

.ci_add_button {
  color: #4282e2 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
