@import "../mixins/fonts";
@import "../colors/colors";
@import "../mixins/padding";
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

.plan-details-card {
  width: 100% !important;
  margin-top: 24px !important;
  min-height: 309px !important;
  border: 1px solid $secondary-platinum !important;
}
.cancel-plan-button {
  font-size: 14px;
  text-decoration: underline;
  color: $severity-Electric-Red;
}

.payment-method-card {
  width: 100% !important;
  margin-top: 32px !important;
  min-height: 160px !important;
  border: 1px solid $secondary-platinum !important;
}
.payment-method-card-container {
  min-height: 66px;
  width: 100%;
  border: 1px solid $secondary-platinum;
  border-radius: 4px;
  padding: 13px;
}

.credit-card-icon-container {
  color: $text-Black-Olive !important;
  padding-top: 2px;
}

.visa-details {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.visa-expire-details {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}

.subscription-button {
  width: 122px !important;
  height: 27px !important;
  border-radius: 15px !important;
  float: right;
  font-weight: 600 !important;
  font-size: 9px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
  text-transform: uppercase !important;
  background-color: #c7f6e0 !important;
  color: #1f9c7b !important;
}

.edit-card-button {
  height: 14px !important;
  width: 14px !important;
  margin-right: 3px !important;
}

.billing-info-card {
  width: 100% !important;
  margin-top: 27px !important;
  min-height: 449px !important;
  border: 1px solid $secondary-platinum !important;
}

.upgrade-image {
  width: 100%;
  height: 235px;
  margin-top: 33px;
  padding: 0px 50px 0px 50px;
}

.upgrade-text1 {
  font-size: 28px;
  font-weight: 800 !important;
  letter-spacing: 0;
  margin-top: 20px;
  padding: 0px 30px 0px 30px;
  line-height: 38px;
  text-align: center;
  color: $text-Black-Olive;
}

.upgrade-100 {
  font-size: 28px;
  font-weight: 800 !important;
  letter-spacing: 0;
  margin-top: 20px;
  line-height: 38px;
  color: #1e8970;
}

.cancelled-title {
  height: 30px;
  font-size: 16px;
  color: red;
  display:inline;
  text-transform: uppercase;
}
.upgrade-text2 {
  font-size: 16px;
  font-weight: 800 !important;
  letter-spacing: 0;
  margin-top: 8px;
  line-height: 22px;
  text-align: center;
  color: $text-Black-Olive;
}
.upgrade-plan-button-container {
  padding: 0px 40px 0px 40px;
  text-align: center;
}
.upgrade-plan-button {
  width: 100% !important;
  height: 47px !important;
  margin-top: 48px !important;
  border-radius: 4px !important;
  color: $secondary-white !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px !important;
  line-height: 24px !important;
  background-color: $primary-Jungle-Green !important;
}

.see-all-plans-button {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 14px;
  -webkit-user-select: none;
  user-select: none;
  color: $Tufts-Blue;
  margin-bottom: 18px;
}

.plan-details-card-container {
  padding: 20px;
}
.plan-details-card-monthly {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  display: inline;
  color: $title-charcoal;
  margin-top: 4px;
}

.plan-details-card-renews {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: $title-charcoal;
}

.plan-details-card-addtional {
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 15px;
  line-height: 20px;
  color: $title-charcoal;
}

.plan-details-card-out {
  font-size: 14px;
  letter-spacing: 0;
  margin-left: 10px;
  line-height: 20px;
  color: $title-charcoal;
}

.plan-details-card-manage-additional-icon {
  height: 12.5px !important;
  width: 12.5px !important;
  color: $Tufts-Blue !important;
  margin-top: 2px !important;
  margin-right: 5px !important;
}
.plan-details-card-manage-additional-container {
  margin-top: 17px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  color: $Tufts-Blue;
  margin-left: 5px;
}

.plan-details-card-see-all-button {
  height: 34px !important;
  width: 143px !important;
  border-radius: 2px !important;
  background-color: $primary-Jungle-Green !important;
  color: $secondary-white !important;
  font-size: 13px !important;
  margin-top: 40px !important;
  text-transform: none !important;
  letter-spacing: 0.5px !important;
  font-weight: 600 !important;
}
.plan-details-card-see-all-button:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}

.plan-details-card-container2 {
  border: 1px solid $secondary-platinum;
  min-height: 163px;
  border-radius: 4px;
  padding: 12px 17px 12px 17px;
}

.plan-details-card-container2-plansummary {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
  color: $secondary-Taupe-Gray;
}

.plan-details-card-container2-datas {
  font-size: 14px !important;
  color: $text-Black-Olive !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.plan-details-card-container2-datas-bold {
  font-size: 14px !important;
  color: $text-Black-Olive !important;
  letter-spacing: 0 !important;
  font-weight: 600;
  line-height: 24px !important;
}
.plan-details-card-container2-datas-amount {
  font-size: 14px !important;
  color: $text-Black-Olive !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: right !important;
}

.plan-details-card-container2-datas-amount-bold {
  font-size: 14px !important;
  color: $text-Black-Olive !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  font-weight: 600;
  text-align: right !important;
}

.billing-info-card-container {
  padding: 23px;
}

.billingnews {
  margin-left: 10px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  color: $text-Black-Olive;
  margin-top: 4px;
}

.upgrade-modal-title {
  font-size: 22px;
  line-height: 30px;
  margin: 33px 0px 0px 33px;
  color: $text-Black-Olive;
}

.upgrade-modal-container {
  padding: 34px 70px 0px 70px;
}

.selected-plan-container {
  width: 100%;
  height: 193px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid $secondary-platinum;
}

.upgrade-plan-button-info {
  font-size: 14px !important;
  margin-top: 5px;
  color: $text-Black-Olive !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.upgrade-plans-button-checkout {
  width: 160px !important;
  height: 34px !important;
  font-size: 13px !important;
  float: right;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 24px !important;
  color: $secondary-white !important;
  border-radius: 2px !important;
  text-transform: none !important;
  background-color: $primary-Jungle-Green !important;
}
.upgrade-plans-button-checkout:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}

.license-amount {
  color: $text-Black-Olive;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: 5px;
  line-height: 21px;
}

.upgrade-confirm-checkout {
  width: 113px !important;
  height: 34px !important;
  font-size: 13px !important;
  float: right;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 24px !important;
  color: $secondary-white !important;
  border-radius: 2px !important;
  text-transform: none !important;
  background-color: $primary-Jungle-Green !important;
}
.upgrade-confirm-checkout:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}
.discount_value {
  height: 19px;
  color: $Green-Crayola;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.14px;
}

.upgrade-plans-button-cancel {
  width: 101px !important;
  height: 34px !important;
  font-size: 13px !important;
  float: right;
  margin-right: 16px !important;
  border: 1px solid $button-border !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
  line-height: 24px !important;
  color: $Spanish-Gray !important;
  border-radius: 2px !important;
  text-transform: none !important;
  background-color: $secondary-white !important;
}

.plan-details-card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan-details-card-icon {
  width: 50px !important;
  margin-top: 63px !important;
  height: 50px !important;
  color: $Tufts-Blue !important;
}

.plan-details-cancel-title {
  margin-top: 22.46px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  color: $text-Black-Olive;
}
.plan-details-cancel-title-desc {
  margin-top: 14px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: $text-Black-Olive;
}
.plan-details-card-icon-container-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 108px 0px 108px;
  text-align: center;
}
.plan-details-cancel-callbutton {
  height: 34px !important;
  width: 191px !important;
  font-size: 13px !important;
  margin-top: 48px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  border-radius: 4px !important;
  text-transform: none !important;
  letter-spacing: 0.5px !important;
  color: $secondary-white !important;
  background-color: $Tufts-Blue !important;
}
.plan-details-cancel-button {
  text-decoration: underline;
  font-size: 14px;
  margin-top: 12px;
  letter-spacing: 0;
  line-height: 20px;
  color: $severity-Electric-Red;
  cursor: pointer;
}

.cancel-subs-container {
  padding: 72px 56px 58px 61px;
}

.billing-history-download-button {
  width: 93px !important;
  height: 24px !important;
  border-radius: 3px !important;
  border: 1px solid $Green-Crayola !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  letter-spacing: 0.39px !important;
  line-height: 24px !important;
  color: $Green-Crayola !important ;
}
.billing-history-download-button-icon {
  height: 10px !important;
  width: 10px !important;
}
.current-plan-button {
  width: 94px !important;
  height: 22px !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 13px !important;
  text-align: center !important;
  border-radius: 15px !important;
  float: right !important;
  margin-top: -15px !important;
  text-transform: uppercase !important;
  color: $primary-steeper-Green !important;
  background-color: $sova-green !important;
}

.monthly-plan {
  height: 30px;
  color: $title-charcoal;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}

.no-hidden-fees-free {
  font-size: 12px;
  letter-spacing: 0;
  display: inline;
  line-height: 20px;
  color: $title-charcoal;
}
.see-details {
  height: 20px;
  font-size: 12px;
  display: inline;
  letter-spacing: 0;
  line-height: 20px;
  color: $secondary-Tufts-Blue;
}
.selected-radio {
  color: $text-Black-Olive !important;
}
.amount {
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 0;
  // line-height: 68px;
  // display: table-cell;
  // vertical-align: top;
  color: $text-Black-Olive;
}
.a-user {
  font-size: 12px;
  font-weight: 600;
  padding-top: 15px;
  padding-left: 5px;
  letter-spacing: 0;
  // line-height: 17px;
  // display: table-cell;
  // vertical-align: top;
}
.were-sorry-to-see-y-copy {
  height: 47px;
  color: $title-charcoal;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}

.here-at-palmtech-we {
  height: 60px;
  max-width: 627px;
  color: $title-charcoal;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.label {
  height: 48px;
  color: $text-Black-Olive;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.rectangle {
  width: 100%;
  margin-top: 30px;
  min-height: 119px;
  padding: 14px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: $sova-light-grey;
  border: 1px solid $secondary-Silver-Sand;
}

.cancelling-your-subs {
  color: $text-Black-Olive;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.schedule-appointment {
  color: $text-Black-Olive;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
.schedule-appointment::before {
  content: "\2022";
  font-size: 17px;
  color: red;
  margin-right: 3px;
}

.keep-plan-button {
  float: right;
  width: 123px !important;
  height: 34px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-left: 14px !important;
  line-height: 24px !important;
  border-radius: 2px !important;
  text-transform: none !important;
  letter-spacing: 0.46px !important;
  color: $secondary-white !important;
  background-color: $secondary-Tufts-Blue !important;
}

.cancel-subscription-button {
  height: 35px !important;
  float: right;
  text-transform: none !important;
  width: 200px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: $sova-red !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  border: 1px solid $sova-red !important;
}

.status-card {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 33px 20px 69px 20px;
  border: 1px solid $secondary-platinum;
  background-color: $secondary-white;
}

.inspection-remaining-button {
  width: 159px !important;
  height: 27px !important;
  border-radius: 15px !important;
  font-weight: 600 !important;
  font-size: 9px !important;
  line-height: 10px !important;
  letter-spacing: 0 !important;
  text-transform: uppercase !important;
  background-color: $text-lavender-blue !important;
  color: $text-egyptian-blue !important;
}

.license-container {
  padding: 21px 18px 27px 18px;
  display: flex;
  justify-content: space-between;
}

.license-modal-container {
  padding: 30px 40px 30px 40px;
}

.license-modal-content {
  display: flex;
  justify-content: space-between;
}

.license-modal-divider {
  margin-top: 20px !important;
  border: 1px solid $Mountain-Mist !important;
}

.total-container {
  width: 100%;
  margin-top: 22px;
  border-radius: 4px;
  padding: 2px 10px 2px 10px;
  border: 1px solid $Mountain-Mist;
  background-color: $background-Cultured;
}

.licenses-addtional-details {
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 25px;
  margin-bottom: 20px;
  line-height: 25px;
  color: $title-charcoal;
}

.ic-check-circle {
  margin-top: 82px !important;
  font-size: 51px !important;
  color: $secondary-Tufts-Blue !important;
}
.ic_auto_renew{
  margin-top: 82px !important;
  font-size: 64px !important;
  color: $secondary-black !important;
}

.ic-delete {
  margin-top: 80px !important;
  height: 44.33px !important;
  width: 51.33px !important;
  color: $text-Black-Olive !important;
}

.purchase-complete {
  height: 45px;
  font-size: 33px;
  margin-top: 35px;
  // font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  color: $title-charcoal;
}

.your-purchase-of-2-a {
  height: 71px;
  max-width: 439px;
  margin-top: 20px;
  display: inline;

  color: $title-charcoal;
  font-size: 14px;
  letter-spacing: 0;
  align-self: center;
  line-height: 20px;
  text-align: center;
}

.discount_value_allplan_container {
  color: $severity-Electric-Red;
  text-decoration: line-through;
}
.discount_value_allplan_text {
  color: $title-charcoal;
  font-weight: 700;
}
