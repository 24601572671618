// imports
@import "../colors/colors";
@import "../mixins/padding";

.import_template_title{
    text-align: center;
    font-size: 18px!important;
    font-weight: 500;
    margin-bottom: 15px!important;
}
.import-template-container{
    padding: 50px;
}
.temp-light{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
.import-template-container-light{
    padding: 10px;
}
.legacy_modal_action{
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        text-transform: uppercase!important;
        min-width: 160px!important;
    }
}
.import_template_content{
    min-height: 150px!important;
    ol{
        margin-left: 15px;
    }
    .subtitle1{
        margin-bottom: 4px;
    }
    .subtitle2{
        margin-bottom: 8px;
        span{
            font-weight: 600; 
        }       
    }
    .subtitle3{
        margin-bottom: 18px;
        span{
            font-weight: 600; 
        }
    }
}
.p-70{
    padding-top: 70px;
}
.imported_template_content{
    min-height: 120px!important;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}
.imported-icon{
    margin-bottom: 10px;
    svg{
        font-size: 58px;
        color: $primary-Jungle-Green;
    }
}
.import_success_message{
    margin-bottom: 20px!important;
}
.redirect-btn{
    background: #239d80 !important;
    height: 34px !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    font-weight: 600 !important;
}
.success-modal{
    gap: 10px;
}
