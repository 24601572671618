// imports
@import "../colors/colors";
@import "../mixins/padding";

.forms-control-service {
    width: 100% !important;
}
.textArea {
    border: 1px solid $Platinum;
    border-radius: 4px;
    padding: 2px 8px;
}

.textArea::placeholder{
    color: rgb(182, 182, 182);
}
.delete-modal-cancel-btn {
    width: 119px !important;
    height: 34px !important;
    border: 1px solid $Spanish-Gray !important;
    color: $primary-Sonic-Silver !important;
    border-radius: 4px !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 13px !important;
    font-weight: 600!important;
    margin-right: 8px!important;
}
.spin-icon-input{
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance:menulist-button !important;
    margin: initial!important;
    }
    /* Firefox */
    input[type=number] {
    -moz-appearance: menulist-button!important;
    }
}