@import "../colors/colors";
@import "../mixins/padding";

.download-the-app {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #1d1f22;
  line-height: 41px;
}

.subHead-download {
  font-style: normal;
  font-weight: 600;
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #3b3b3b;
}

.div-download {
  max-width: "569px";
  text-align: center;
}

.download-the-app-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #3b3b3b;
}

.download-link-modal {
  position: relative;
  top: 30%;
  left: 50%;
  margin-bottom: 20%;
  transform: translate(-50%, -25%);
  max-width: 803px;
  min-height: 664px;
  border-radius: 2px;
  background-color: $secondary-white;
  // border: 2px solid $secondary-platinum;
  padding: 4px;
  outline: 0;
}

.left {
  display: flex;
  justify-content: end;
}

.download-the-app-sub-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-top: 12px;
  margin-left: 24px;
  color: #3b3b3b;
}

.app-store-image {
  width: 138px;
  margin-top: 10px;
  height: 47px;
}

@media screen and (max-width: 900px) {
  .app-store-image {
    width: 138px;
    margin-top: 10px;
    height: 47px;
  }

  .left {
    display: flex;
    justify-content: center;
  }
}

.web-app-link-sub-container {
  max-width: 550px !important;
  margin-left: 24px !important;
  margin-top: 12px !important;
  cursor: pointer;
}

.web-app-link-sub-first {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: inline;
  color: #3b3b3b;
}

.web-app-link-sub-second {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #4282e2;
  display: inline;
}
