// imports
@import "../colors/colors";
@import "../mixins/padding";

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
*:not(.editor-class *) {
  font-family: "Open Sans", sans-serif;
}

.isFlex {
  display: flex !important;
  gap: 3px;
}
.agreements-table-wrapper {
  padding: 34px 18px !important;
}
.agreements-data-table {
  cursor: pointer;
  &:nth-child(odd) {
    background-color: $secondary-cultered;
  }
  td span {
    font-size: 14px !important;
    line-height: 20px;
  }
  td.agreement_name {
    width: 700px;
  }
  td .date-display {
    font-size: 12px !important;
    color: $title-graphite !important;
  }
}
.agreements-table-main thead {
  span {
    font-size: 12px;
    color: $Spanish-Gray2;
  }
  td:first-child {
    padding-left: 15px;
  }
  .action-column {
    padding-right: 25px;
    // padding-left: 16px;
    text-align: center;
  }
}
.agreements-table-main tbody {
  tr {
    height: 60px;
  }
  td {
    padding: 0px 40px 0px 0px;
    span {
      color: $title-charcoal;
    }
  }
  td:first-child {
    padding-left: 31px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  td:last-child {
    padding-right: 25px;
    span svg:first-child {
      margin-right: 10px;
    }
  }
  .teams-email-data {
    max-width: 230px;
    span {
      max-width: 220px;
      word-wrap: break-word;
      display: block;
    }
  }
}
.agreements-table-main .agreements-body {
  tr {
    height: 60px;
  }
  td {
    padding: 0px 40px 0px 0px;
    span {
      color: $title-charcoal;
      // max-width: 150px;
      word-wrap: break-word;
      display: block;
    }
    .team-role {
      display: flex;
    }
    .agr-description {
      max-width: 400px;
      min-width: 250px;
    }
  }
  td:first-child {
    padding-left: 16px;
  }
  td:last-child {
    padding-right: 25px;
    span svg:first-child {
      margin-right: 10px;
    }
  }
  .teams-email-data {
    max-width: 230px;
    span {
      max-width: 220px;
      word-wrap: break-word;
      display: block;
    }
  }
}
.editor-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}
.editor-title,
.editor-title span {
  font-size: 22px;
  line-height: 30px;
  color: $title-charcoal;
}
.editor-title-wrapper .agreements-subtitle {
  color: $title-charcoal;
  font-size: 14px;
  margin: 5px 0px 8px 0px;
  max-width: 850px;
  line-height: 22px;
}
.color3B {
  color: $text-Black-Olive;
}
.editor_details_subtitle {
  color: $title-charcoal;
  font-size: 14px;
  margin: 5px 0px 50px 0px;
  line-height: 20px;
}
.agreement_consent {
  margin-bottom: 17px !important;
  a {
    cursor: pointer;
    span {
      font-weight: 600;
      color: $primary-Jungle-Green;
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
      margin-left: 28px;
    }
  }
  span:last-child {
    font-size: 14px;
  }
  .agreement_checkbox {
    color: $text-Black-Olive !important;
  }
  label {
    color: $Dark-Charcoal2;
  }
}
.agreement__toggle {
  display: flex;
  justify-content: space-between;
}
.wrapper-class {
  // padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  min-height: 440px;
}

.editor-class .sun-editor {
  min-height: 440px !important;

  font-size: 16px;
}
.toolbar-class {
  background: #ccc;
  margin-bottom: 0px !important;
}
.placeholder-head {
  background: #fff;
  border: 1px solid #e7e7e9;
  font-size: 20px !important;
  padding: 10px 15px;
}
.placeholder {
  background: #fff;
  min-height: 300px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 5px;
  border-left: 1px solid #e7e7e9;
  border-right: 1px solid #e7e7e9;
  border-bottom: 1px solid #e7e7e9;
  li {
    list-style: none;
    cursor: pointer;
  }
}
.placeholder-list {
  padding: 8px 5px;
  color: #000 !important;
  justify-content: flex-start !important;
  padding: 8px 5px;
  width: 100% !important;
  span {
    display: flex;
    align-items: center;
    font-size: 14px;
    svg {
      padding-right: 5px;
      font-size: 29px !important;
    }
  }
  &:hover {
    background-color: #f3f3f3;
  }
}
.agreement-btn {
  background: $Green-Crayola !important;
  width: 203px !important;
  height: 34px !important;
  font-size: 13px !important;
  border-radius: 2px !important;
  font-weight: 600 !important;
  color: $secondary-white !important;
}
.editor-btn {
  width: 119px !important;
}
.agreement-action {
  display: flex;
  gap: 23px;
  align-items: center;
  span span {
    font-weight: 600;
    color: $title-charcoal;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    cursor: pointer;
  }
}
.placeholder-search {
  position: relative;
  .MuiOutlinedInput-root {
    padding-left: 26px !important;
    border-radius: 0px !important;
  }
  button {
    padding-right: 0px;
  }
  background-color: $secondary-white;
  & > div:first-child {
    width: 100%;
  }
  .search-merge {
    position: absolute;
    left: 10px;
    top: 12px;
    cursor: pointer;
    font-size: 18px !important;
  }
}
// inline editor styles
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  //   border-bottom: 1px dashed #999999;
  outline: none;
}

.inline-text_copy--active {
  cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border-bottom: 1px dashed #666666;
  text-align: left;
}
.inline-edit-icon {
  color: $text-Black-Olive !important;
  font-size: 10px;
  margin-left: 5px;
}
.editor-class span {
  font-size: unset;
}
// .sticky-mergeField{
//     position: sticky;
//     top: 125px;
// }
// draging placeholder css
.draggable.dragging {
  color: $Tufts-Blue;
  background: $text-lavender-blue;
}

// webview page styles.
.web-view-wrapper {
  padding: 20px 150px;
}
.web-view-container {
  margin: 0px !important;
}
.web-view-content {
  background-color: $secondary-white;
  width: 100%;
  min-height: 100vh;
  line-height: 26px;
  padding: 25px;
  font-size: 14px;
  h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .agreement_consent {
    margin: 10px 0px;
    svg {
      font-size: 18px;
      margin-top: 2px;
    }
  }
  .forms-control-webview {
    width: 100% !important;
    margin-bottom: 25px !important;
    padding-right: 15px !important;
  }
  .agreement-action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .right-btn-group {
    display: flex;
    gap: 10px;
  }
  .cancel-btn {
    width: 101px !important;
    height: 34px !important;
    border: 1px solid $Spanish-Gray !important;
    color: $primary-Sonic-Silver !important;
    border-radius: 2px !important;
    &:hover {
      background: $primary-Jungle-Green !important;
      color: $secondary-white !important;
    }
    span {
      font-family: "Open Sans", sans-serif !important;
      font-size: 13px !important;
      font-weight: 600;
    }
  }
  .agree-btn {
    background: $primary-Jungle-Green !important;
    width: 153px !important;
    height: 34px !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    font-weight: 600 !important;
    color: $secondary-white !important;
  }
  .reject-btn {
    color: $primary-Sonic-Silver !important;
    width: 75px !important;
    height: 34px !important;
    border-radius: 2px !important;
    margin-right: 15px !important;
    &:hover {
      background: transparent !important;
    }
    .reject-text {
      font-family: "Open Sans", sans-serif !important;
      font-size: 13px !important;
      border-bottom: 1px solid $primary-Sonic-Silver !important;
      padding: 0px 3px;
      font-weight: 600;
    }
  }
}

.agreement-view {
  width: 100% !important;
  line-height: normal !important;
  p:first-child {
    font-size: 14px !important;
  }
  p {
    margin-bottom: 10px;
  }
  table {
    border-collapse: collapse !important;
  }
  table td,
  table tr {
    border: 1px solid #ddd;
  }
}
#agreeTermsID {
  color: $severity-Electric-Red;
  margin-top: -20px;
}
// agreement accept page css
.agreement-action-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agreement-accept-action {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-direction: column !important;
  min-height: 500px;
  max-width: 450px;
  border: 1px solid $Silver-Sand;
  background-color: $secondary-white;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    gap: 15px;
    svg {
      font-size: 52px;
    }
    .signature-header {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .signature-content {
    font-size: 14px;
    text-align: center;
    line-height: 26px;
    span {
      font-weight: 600;
    }
  }
}
#agreement-error-message {
  color: $severity-Electric-Red !important;
  font-size: 12px;
}
.agreement-notify {
  display: flex;
}

.agreement_navigate {
  color: $Tufts-Blue !important;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  svg {
    font-size: 12px;
    margin-left: 4px;
    margin-top: 3px;
  }
}
.agreement_editor_title_text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  color: $title-charcoal;
  .navigate_text {
    font-size: 14px;
    color: $Tufts-Blue !important;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}
// .sun-editor{
//     .se-wrapper{
//         min-height: 448px!important;
//     }
// }
// agreement accept page css

// agree agreement terms modal pages css
.agreement-terms-modal {
  .MuiDialog-paper {
    padding: 50px 56px 36px 55px;
    align-items: center;
    width: 750px;
    max-width: 750px;
  }
  h2 {
    color: $title-charcoal;
    font-size: 22px;
    line-height: 30px;
    padding: 9px;
    margin-bottom: 25px;
  }
  .agreement-terms-text {
    text-align: center;
    p:nth-child(2) {
      margin: 15px 0px !important;
    }
  }
  .MuiDialogActions-root {
    justify-content: center;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: $title-charcoal;
    margin-bottom: 12px;
  }
  .close-icon {
    color: $text-Black-Olive;
  }
}
.create-own-agreement-terms-modal {
  .MuiDialog-paper {
    padding: 50px 56px 36px 55px;
    align-items: center;
    width: 700px;
    max-width: 700px;
  }
}
.included-agreement-terms-modal {
  .MuiDialog-paper {
    padding: 50px 56px 36px 55px;
    align-items: center;
    width: 800px;
    max-width: 800px;
  }
  .MuiDialogContent-root {
    overflow: unset;
  }
  h2 {
    margin-bottom: 15px !important;
  }
  p:first-child {
    margin-bottom: 31px;
  }
  .MuiDialogActions-root {
    justify-content: space-between;
    width: 100%;
  }
  ::-webkit-scrollbar {
    // height: 12px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: $title-charcoal;
    -webkit-border-radius: 1ex;
  }
  .btn-disabled {
    color: $secondary-white !important;
    border-radius: 2px !important;
    cursor: not-allowed;
    background-color: $secondary-Gray !important;
    p {
      font-family: "Open Sans", sans-serif !important;
      font-size: 13px !important;
      font-weight: 600;
    }
  }
}
.default-btn-agreement-terms {
  background: $primary-Jungle-Green !important;
  min-width: 167px !important;
  padding: 5px 29px !important;
  height: 34px !important;
  font-size: 13px !important;
  border-radius: 2px !important;
  font-weight: 600 !important;
  color: $secondary-white !important;
}
.default-btn-agreement-terms:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}
.ml-17 {
  margin-left: 17px !important;
}
.included_agreement_img {
  height: 368px;
  max-height: 368px;
  width: 100%;
  border: 1px solid $Mountain-Mist;
  overflow-y: scroll;
  img {
    width: 98%;
  }
}
.terms {
  text-align: initial !important;
  line-height: 20px;
  span {
    font-weight: 800;
  }
}
.agreement_terms_and_condition_checkbox .terms_and_condition {
  font-size: 14px;
}
@media only screen and (max-width: 900px) {
  .web-view-wrapper {
    padding: 20px 26px 20px 10px;
  }
}
@media only screen and (max-width: 700px) {
  .agreement-terms-modal {
    h2 {
      text-align: center !important;
    }
    .MuiDialogActions-root {
      display: flex;
      flex-direction: column;
      gap: 8px;
      button {
        width: 100%;
      }
    }
    .ml-17 {
      margin-left: 0px !important;
    }
    .included-buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  .agreement-terms-modal {
    .MuiDialog-paper {
      padding: 30px 0px 30px 0px !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .web-view-content {
    .agree-btn {
      width: 110px !important;
    }
    .reject-btn {
      margin-right: 0px !important;
    }
  }
  .editor-title-wrapper {
    padding: 0px 10px;
  }
}
