// imports
@import "../colors/colors";
@import "../mixins/padding";

.unbounce-create-modal-main {
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: hidden;
    width: 2px;
  }
}

.unbounce-modal {
  position: relative;
  top: 45%;
  left: 50%;
  margin-bottom: 20%;
  transform: translate(-50%, -25%);
  max-width: 331px;
  min-height: 323px;
  border-radius: 2px;
  background-color: $secondary-white;
  // border: 2px solid $secondary-platinum;
  padding: 4px;
  outline: 0;
}

.unbounce_terms_and_condition {
  user-select: none;
  display: inline;
  font-size: 14px;
}
.unbounce_terms_and_condition_hyperlink {
  font-size: 14px;
  display: inline;
  color: $secondary-Tufts-Blue;
  text-decoration: underline;
}
.unbounce-create-modal-grid-main {
  padding: 0;
}

.unbounce_button {
  color: $secondary-white;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-family: "Open Sans", sans-serif !important;
  text-transform: none !important;
  background-color: $primary-Jungle-Green !important;
  letter-spacing: 0.44px !important;
  margin-top: 2px !important;
  margin-bottom: 10px !important;
}
.unbounce_button:hover {
  background-color: $secondary-Celadon-green !important;
}
.unbounce_button:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}

.dashboard {
  min-height: 100vh;
  background-size: cover;
  background-image: url("../../../assets/images/dashboard.png");
}
.dashboard-mobile {
  min-height: 100vh;
  background-image: url("../../../assets/images/dashboard-mobile.png");
}
.requirement-pw {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  color: #888888;
}
